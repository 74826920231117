import { Injectable, inject } from '@angular/core';
import { RealEstateService } from '@app/core/services/realEstate/real-estate.service';
import { ValidationDataService } from '@app/core/services/validation/validation-data.service';
import { RealEstateDTO, RealEstateUsageEmbeddable } from 'src/generated/generatedEntities';
import { RoomHeightLimitsWarningService } from '../room-height/room-height-limits-warning.service';
import { SliderIdentMappingService } from '../slider/slider-ident-mapping.service';
import { SliderPresetService } from '../slider/slider-preset.service';
import { FacadePresetService } from './facade-preset.service';
import { MinergieEcoPresetService } from './minergie-eco-preset.service';
import { MinergieTypePresetService } from './minergie-type-preset.service';
import { RoofTypePresetService } from './roof-type-preset.service';
import { RouteUtilService } from '@app/calculators/components/util/route-util.service';

@Injectable({
  providedIn: 'root',
})
export class PresetWrapperService {
  private realEstateService = inject(RealEstateService);
  private sliderPresetService = inject(SliderPresetService);
  private facadePresetService = inject(FacadePresetService);
  private minergieTypePresetService = inject(MinergieTypePresetService);
  private minergieEcoPresetService = inject(MinergieEcoPresetService);
  private roofTypePresetService = inject(RoofTypePresetService);
  private sliderIdentMappingService = inject(SliderIdentMappingService);
  private validationDataService = inject(ValidationDataService);
  private roomHeightLimitsWarningService = inject(RoomHeightLimitsWarningService);
  private _routeUtil = inject(RouteUtilService);
  realEstate: RealEstateDTO;
  isPresetSliderModelValuesEnabled: boolean = true;
  realEstateContainer: any;

  constructor() {
    if (this.realEstateService.realEstateContainer != null) {
      this.realEstate = this.realEstateService.realEstateContainer;
    } else {
      this.realEstate = {} as RealEstateDTO;
    }
  }

  setCorrectSliderModelValues(usages: RealEstateUsageEmbeddable[], realEstate?: RealEstateDTO) {
    if (realEstate != null) {
      this.realEstate = realEstate;
    }
    // check if route ends with /input. In case of route /input instead of /edit we directly apply presets
    // we apply this convention for all our forms, including /operational-form /process-form and /rebuild-form /lcc-form etc
    this.isPresetSliderModelValuesEnabled = this._routeUtil.isRouteMap('input');
    var correctUsages = this.sliderPresetService.correctUsages(usages);
    // console.log('correct usage in serives', correctUsages);
    if (correctUsages.length == 0) {
      return;
    }
    // first set presets on fields, then sliders, because slider presets might depend on field presets
    if (this.isPresetSliderModelValuesEnabled && correctUsages) {
      this.facadePresetService.presetFacade(correctUsages, this.realEstate);
      this.minergieTypePresetService.presetMinergieType(correctUsages, this.realEstate);
      this.minergieEcoPresetService.presetMinergieEco(correctUsages, this.realEstate);
      this.roofTypePresetService.presetRoofType(correctUsages, this.realEstate);
    }

    var weightedArr = this.sliderPresetService.getWeightedPresetValues(correctUsages, this.realEstate);

    if (weightedArr) {
      var weightedIntervalArr = this.sliderPresetService.getWeightedPresetIntervals(correctUsages);
      this.sliderPresetService.setSliderModelValuesArr(
        weightedArr,
        weightedIntervalArr,
        this.realEstate,
        this.isPresetSliderModelValuesEnabled,
      );
      // Update vorbereitungsarbeit slider in case of replacement real estate.
      if (this.realEstate.replacementOriginalRealEstateId != null) {
        var minimumVorbereitungsArbeiten = this.realEstate.geometry.totalFloorArea416 < 1000 ? 4 - 1 : 5 - 1;
        if (weightedArr[2] < minimumVorbereitungsArbeiten) {
          console.info(
            'Overwrite preset of Vorbereitungsarbeiten from ' +
              weightedArr[2] +
              ' to ' +
              minimumVorbereitungsArbeiten +
              ' because current real estate is a replacement ("Ersatzbau")',
          );
          this.realEstate.quality.qualitaetvorbereitungsarbeiten =
            this.sliderIdentMappingService.getSliderIdentByMore(minimumVorbereitungsArbeiten);
        }
      }
    }
  }

  /**
   * Set preset values for sliders if they are not set yet. Otherwise, backend cant calculate.
   * @param realEstate
   */
  setSliderValues(realEstate: RealEstateDTO) {
    this.realEstate = realEstate;

    if (this.realEstate.quality.fensterAnteil == null) {
      let res = this.sliderPresetService.requestPresetEvent(this.realEstate, 'realEstate.quality.fensterAnteil');
      this.realEstate.quality.fensterAnteil = res.preset;
      this.realEstate.quality.fensterAnteilBackend = res.preset;
      this.realEstate.quality.fensterAnteilInterval = res.interval;
    }

    if (this.realEstate.quality.qualitaetDerGebaeudeform2 == null) {
      let res = this.sliderPresetService.requestPresetEvent(
        this.realEstate,
        'realEstate.quality.qualitaetDerGebaeudeform2',
      );
      this.realEstate.quality.qualitaetDerGebaeudeform2 = res.preset;
      this.realEstate.quality.qualitaetDerGebaeudeform2Backend = res.preset;
      this.realEstate.quality.qualitaetDerGebaeudeform2Interval = res.interval;
    }

    if (this.realEstate.quality.qualitaetVorfertigung == null) {
      let res = this.sliderPresetService.requestPresetEvent(
        this.realEstate,
        'realEstate.quality.qualitaetVorfertigung',
      );
      this.realEstate.quality.qualitaetVorfertigung = res.preset;
      this.realEstate.quality.qualitaetVorfertigungBackend = res.preset;
      this.realEstate.quality.qualitaetVorfertigungInterval = res.interval;
    }

    if (this.realEstate.quality.qualitaetvorbereitungsarbeiten == null) {
      let res = this.sliderPresetService.requestPresetEvent(
        this.realEstate,
        'realEstate.quality.qualitaetvorbereitungsarbeiten',
      );
      this.realEstate.quality.qualitaetvorbereitungsarbeiten = res.preset;
      this.realEstate.quality.qualitaetvorbereitungsarbeitenBackend = res.preset;
      this.realEstate.quality.qualitaetvorbereitungsarbeitenInterval = res.interval;
    }

    if (this.realEstate.quality.qualitaetGrundstueck == null) {
      let res = this.sliderPresetService.requestPresetEvent(this.realEstate, 'realEstate.quality.qualitaetGrundstueck');
      this.realEstate.quality.qualitaetGrundstueck = res.preset;
      this.realEstate.quality.qualitaetGrundstueckBackend = res.preset;
      this.realEstate.quality.qualitaetGrundstueckInterval = res.interval;
    }

    if (this.realEstate.quality.qualitaetUmgebung == null) {
      let res = this.sliderPresetService.requestPresetEvent(this.realEstate, 'realEstate.quality.qualitaetUmgebung');
      this.realEstate.quality.qualitaetUmgebung = res.preset;
      this.realEstate.quality.qualitaetUmgebungBackend = res.preset;
      this.realEstate.quality.qualitaetUmgebungInterval = res.interval;
    }
    if (this.realEstate.quality.qualitaetAusbau1 == null) {
      let res = this.sliderPresetService.requestPresetEvent(this.realEstate, 'realEstate.quality.qualitaetAusbau1');
      this.realEstate.quality.qualitaetAusbau1 = res.preset;
      this.realEstate.quality.qualitaetAusbau1Backend = res.preset;
      this.realEstate.quality.qualitaetAusbau1Interval = res.interval;
    }
    if (this.realEstate.quality.qualitaetAusbau2 == null) {
      let res = this.sliderPresetService.requestPresetEvent(this.realEstate, 'realEstate.quality.qualitaetAusbau2');
      this.realEstate.quality.qualitaetAusbau2 = res.preset;
      this.realEstate.quality.qualitaetAusbau2Backend = res.preset;
      this.realEstate.quality.qualitaetAusbau2Interval = res.interval;
    }
    if (this.realEstate.quality.qualitaetSanitaeranlage == null) {
      let res = this.sliderPresetService.requestPresetEvent(
        this.realEstate,
        'realEstate.quality.qualitaetSanitaeranlage',
      );
      this.realEstate.quality.qualitaetSanitaeranlage = res.preset;
      this.realEstate.quality.qualitaetSanitaeranlageBackend = res.preset;
      this.realEstate.quality.qualitaetSanitaeranlageInterval = res.interval;
    }

    if (this.realEstate.quality.qualitaetHeizungsanlage == null) {
      let res = this.sliderPresetService.requestPresetEvent(
        this.realEstate,
        'realEstate.quality.qualitaetHeizungsanlage',
      );
      this.realEstate.quality.qualitaetHeizungsanlage = res.preset;
      this.realEstate.quality.qualitaetHeizungsanlageBackend = res.preset;
      this.realEstate.quality.qualitaetHeizungsanlageInterval = res.interval;
    }
    if (this.realEstate.quality.qualitaetElektroAnlage == null) {
      let res = this.sliderPresetService.requestPresetEvent(
        this.realEstate,
        'realEstate.quality.qualitaetElektroAnlage',
      );
      this.realEstate.quality.qualitaetElektroAnlage = res.preset;
      this.realEstate.quality.qualitaetElektroAnlageBackend = res.preset;
      this.realEstate.quality.qualitaetElektroAnlageInterval = res.interval;
    }
  }

  /*
  @param : realEstate
  Making second argument as optional because other forms also using this which shouldn't happen.. this watch is just for new-building cost
  */
  realEstateUsages(usages: RealEstateUsageEmbeddable[], realEstate?: RealEstateDTO | any) {
    this.realEstate = realEstate;
    if (!this.validationDataService.getIsCalculating()) {
      this.setCorrectSliderModelValues(usages, this.realEstate);
      this.roomHeightLimitsWarningService.showWarningIfOutOfLimit(this.realEstate);
    }
  }

  requestPresetMark() {
    this.setCorrectSliderModelValues(this.realEstateContainer.usages);
  }
}
