import { inject, Injectable } from '@angular/core';
import { UsageToEnumResolverService } from './usage-to-enum-resolver.service';
import { Logger } from '@shared';
import { FacadeType, RealEstateDTO, RealEstateUsageEmbeddable, RebuildEstateDTO } from '@generated/generatedEntities';
import { UsageStandardUsed, UsageTypeKvUsed } from '@generated/extended';

const log = new Logger('FacadePresetService');
@Injectable({
  providedIn: 'root',
})
export class FacadePresetService {
  private usageToEnumResolverService = inject(UsageToEnumResolverService);
  // service: any;
  // FacadePresetService(UsageToEnumResolverService: any) {
  //   // this.service = {
  //   //   presetFacade: this.presetFacade,
  //   // };
  // }

  presetFacade(usages: RealEstateUsageEmbeddable[], realEstateContainer: RealEstateDTO | RebuildEstateDTO) {
    var facadePreset = this.usageToEnumResolverService.resolve(usages, this.getMapping());
    // console.log('faced value', facadePreset);
    realEstateContainer.quality.facadeType = facadePreset as FacadeType;
    if (realEstateContainer.metaData.tasks.includes('LCA')) {
      realEstateContainer.quality.facadeTypes[0].facadeType = facadePreset as FacadeType;
    }
  }
  getMapping(): {
    [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: FacadeType };
  } {
    return this.mapping;
  }

  //return service;
  mapping: {
    [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: FacadeType };
  } = {
    // HOCHBAU: { LOW: 'AUSSENDAEMMUNG_PUTZ_EDEL', MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL', HIGH: 'AUSSENDAEMMUNG_PUTZ_EDEL' },
    WOHNBAUTEN__TITLE: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // NICHTWOHNBAUTEN__TITLE: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EDEL',
    //   MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
    //   HIGH: 'AUSSENDAEMMUNG_PUTZ_EDEL',
    // },
    // WOHNBAUTEN__WHG_MIN_2: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    WOHNBAUTEN__EFH_REIHEN: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // WOHNBAUTEN__WOHNHEIME_HAEUSER: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // WOHNBAUTEN_BEHELFSWOHNUNGEN: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    WOHNBAUTEN__MFH: { LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH', MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    WOHNBAUTEN__TERRASSENHAEUSER: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // WOHNBAUTEN__E_RFH__EFH: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // WOHNBAUTEN__E_RFH__REFH: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    WOHNBAUTEN__ALTERSWOHNUNGEN: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    WOHNBAUTEN__ALTERSHEIME: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    WOHNBAUTEN__STUDENTENWOHNHAEUSER: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    WOHNBAUTEN__PERSONALHAEUSER: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__BEHINDERTE: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__KINDER: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__SCHUELER: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    BUERO_HANDELSBAUTEN__BVB: { LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    SCHULBAUTEN__TITLE: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'AUSSENDAEMMUNG_PUTZ_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    BUERO_HANDELSBAUTEN__HANDELSBAU: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // LAGER_PRODUKTION: {
    //   LOW: 'STAHL_KASSETENWANDFASSADE_EINFACH',
    //   MEDIUM: 'STAHL_KASSETENWANDFASSADE_EINFACH',
    //   HIGH: 'STAHL_KASSETENWANDFASSADE',
    // },
    LANDWIRTSCHAFT__TITLE: { LOW: 'NICHT_ISOLIERT_HOLZ', MEDIUM: 'NICHT_ISOLIERT_HOLZ', HIGH: 'NICHT_ISOLIERT_HOLZ' },
    // TECHNISCHE_ANLAGEN__TITLE: {
    //   LOW: 'SICHTBETONFASSADE_INNEN_SICHTBETON',
    //   MEDIUM: 'SICHTBETONFASSADE_INNEN_SICHTBETON',
    //   HIGH: 'SICHTBETONFASSADE_INNEN_SICHTBETON',
    // },
    JUSTIZBAUTEN__TITLE: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    GESUNDHEITSBAUTEN__TITLE: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    KULTUS__TITLE: {
      LOW: 'EINSTEINMAUERWERK_VERPUTZT',
      MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
      HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    },
    KULTUR__TITLE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    VERKEHRSBAUTEN__TITLE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    SCHUTZBAUTEN__TITLE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    BUERO_HANDELSBAUTEN__BVB__BUERO: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__VERWALTUNG_BANK: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__BVB__BEHOERDENHAEUSER: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK__EDELROHBAU: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK__VOLLAUSBAU: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG__EDELROHBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG__VOLLAUSBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK__EDELROHBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK__VOLLAUSBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK__BANKAUSBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__GEMEINDEHAEUSER: {
      LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS_STADTHAUS_STADTVERWALTUNG: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__STADTHAUS_STADTVERWALTUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    SCHULBAUTEN__KINDERGAERTEN_HORTE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    SCHULBAUTEN__VOLKSSCHULEN: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // SCHULBAUTEN__BERUFSSCHULEN: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN: {
      LOW: 'MASSIVBAU_EDEL',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // SCHULBAUTEN__AULA: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    SCHULBAUTEN__BIBLIOTHEKEN: { LOW: 'MASSIVBAU_EDEL', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    SCHULBAUTEN__FORSCHUNGSBAUTEN: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    SCHULBAUTEN__BERUFSSCHULEN__BERUF: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    SCHULBAUTEN__BERUFSSCHULEN__GYM: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // SCHULBAUTEN__HOERSAALGEBAEUDE: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // SCHULBAUTEN__LEHRE_OHNE_LABOR: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // SCHULBAUTEN__LEHRE_MIT_LABOR: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // SCHULBAUTEN__BIBLIOTHEKEN__BIBLIOTHEK: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // SCHULBAUTEN__BIBLIOTHEKEN__STAATSARCHIV: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR__MEDIUM: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR__HIGH: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__GROSSHANDEL: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__STILLES_GEWERBE_ATELIER: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU__EDELROHBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU__VOLLAUSBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU_KIOSK: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__STILLES_GEWERBE_ATELIER__EDELROHBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__STILLES_GEWERBE_ATELIER__VOLLAUSBAU: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'GLASSFASSADE_GLASSFASSADE',
    },
    // BUERO_HANDELSBAUTEN__GROSSHANDEL__GROSSHANDELSBAU_GROSSMARKT: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM__EDELROHBAU: {
    //   LOW: 'MASSIVBAU_EDEL',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'GLASSFASSADE_GLASSFASSADE',
    // },
    // BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM__VOLLAUSBAU: {
    //   LOW: 'MASSIVBAU_EDEL',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'GLASSFASSADE_GLASSFASSADE',
    // },
    // BUERO_HANDELSBAUTEN__GROSSHANDEL__GROSSHANDELSBAU_GROSSMARKT__EDELROHBAU: {
    //   LOW: 'STAHL_KASSETENWANDFASSADE',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // BUERO_HANDELSBAUTEN__GROSSHANDEL__GROSSHANDELSBAU_GROSSMARKT__VOLLAUSBAU: {
    //   LOW: 'STAHL_KASSETENWANDFASSADE',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE: {
      LOW: 'STAHL_KASSETENWANDFASSADE',
      MEDIUM: 'HOLZ_EINFACH',
      HIGH: 'HOLZ_EDEL',
    },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MESSEZENTRUM: {
    //   LOW: 'STAHL_KASSETENWANDFASSADE',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    LAGERBAUTEN__TITLE: {
      LOW: 'STAHL_KASSETENWANDFASSADE_EINFACH',
      MEDIUM: 'STAHL_KASSETENWANDFASSADE_EINFACH',
      HIGH: 'STAHL_KASSETENWANDFASSADE',
    },
    LAGER_PRODUKTION__PRODUKTION: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN: {
      LOW: 'STAHL_KASSETENWANDFASSADE_EINFACH',
      MEDIUM: 'STAHL_KASSETENWANDFASSADE',
      HIGH: 'MASSIVBAU_EDEL',
    },
    LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN: {
      LOW: 'STAHL_KASSETENWANDFASSADE_EINFACH',
      MEDIUM: 'STAHL_KASSETENWANDFASSADE_EINFACH',
      HIGH: 'STAHL_KASSETENWANDFASSADE',
    },
    LAGERBAUTEN_MEHRSTOECKIGE: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // LAGERBAUTEN__MECHANISIERTE_LAGER_KUEHLLAGER: {
    //   LOW: 'STAHL_KASSETENWANDFASSADE_EINFACH',
    //   MEDIUM: 'STAHL_KASSETENWANDFASSADE_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // LAGERBAUTEN__SILOBAUTEN: { LOW: 'NICHT_ISOLIERT_HOLZ', MEDIUM: 'NICHT_ISOLIERT_HOLZ', HIGH: 'NICHT_ISOLIERT_HOLZ' },
    LAGERBAUTEN__VERTEILZENTRALEN: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_UNBEHEIZT: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_BEHEIZT: {
    //   LOW: 'STAHL_KASSETENWANDFASSADE_EINFACH',
    //   MEDIUM: 'STAHL_KASSETENWANDFASSADE_EINFACH',
    //   HIGH: 'STAHL_KASSETENWANDFASSADE',
    // },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN__OFFEN: {
    //   LOW: 'Keine Aussenwand',
    //   MEDIUM: 'Keine Aussenwand',
    //   HIGH: 'Keine Aussenwand',
    // },
    // LAGERBAUTEN__VERTEILZENTRALEN_NONFOOD: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // LAGERBAUTEN__VERTEILZENTRALEN_FOOD: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    LANDWIRTSCHAFT__SCHEUNEN: {
      LOW: 'NICHT_ISOLIERT_HOLZ',
      MEDIUM: 'NICHT_ISOLIERT_HOLZ',
      HIGH: 'NICHT_ISOLIERT_HOLZ',
    },
    // LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LANDWIRTSCHAFT__TIERMEDIZIN: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LANDWIRTSCHAFT__SCHEUNEN__SCHUPPEN: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LANDWIRTSCHAFT__SCHEUNEN__SCHEUNE: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LANDWIRTSCHAFT__SCHEUNEN__SILO: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG: {
      LOW: 'NICHT_ISOLIERT_HOLZ',
      MEDIUM: 'NICHT_ISOLIERT_HOLZ',
      HIGH: 'NICHT_ISOLIERT_HOLZ',
    },
    // LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__PRODUKTIONSANLAGE: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__TIERHAUS: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__SCHLACHTHOF: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EINFACH',
    // },
    // LANDWIRTSCHAFT__TIERMEDIZIN__TIERHEIM: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // LANDWIRTSCHAFT__TIERMEDIZIN__TIERSPITAL: {
    //   LOW: 'AUSSENDAEMMUNG_PUTZ_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG__GAERTNEREI: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG__GEWAECHSHAEUSER: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // FREIZEITBAUTEN_SPORTBAUTEN_ERHOLUNGSBAUTEN__GAERTEN_GEWAECHSHAEUSER: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG__GEWAECHSHAEUSER_AUSTELLUNG: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // TECH__EKR: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // TECHNISCHE_ANLAGEN__ABFALLBESEITIGUNG: {
    //   LOW: 'SICHTBETONFASSADE_INNEN_SICHTBETON',
    //   MEDIUM: 'SICHTBETONFASSADE_INNEN_SICHTBETON',
    //   HIGH: 'SICHTBETONFASSADE_INNEN_SICHTBETON',
    // },
    // TECH__EKR__ENERGIEERZEUGUNG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // TECH__EKR__ENERGIESTEUERUNG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // TECH__EKR__ENERGIEVERSORGUNG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__POSTBETRIEBE: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__RECHENZENTREN: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // TECHNISCHE_ANLAGEN__UNTERWERK: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // TECH__EKR__ENERGIESTEUERUNG__FUNKSTATION: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // TECHNISCHE_ANLAGEN__HEIZZENTRALE: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // TECHNISCHE_ANLAGEN__TANKANLAGEN_TITLE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // TECHNISCHE_ANLAGEN__TANKANLAGEN: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // VERKEHRSBAUTEN__TANKSTELLE: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // TECH__WASSER__QUELLFASSUNG: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // TECH__WASSER__GRUNDWASSER: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // TECH__WASSER__RESERVOIR: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // TECHNISCHE_ANLAGEN__WASSERAUFBEREITUNGSANLAGEN: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // TECHNISCHE_ANLAGEN__WASSERENTSORGUNG__KLAERANLAGE: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // TECHNISCHE_ANLAGEN__ROHSTOFF__GEWINNUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // TECHNISCHE_ANLAGEN__ROHSTOFF__AUFBEREITUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // TECHNISCHE_ANLAGEN__ABFALLBESEITIGUNG__KEHRICHTVERBRENNUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // TECHNISCHE_ANLAGEN__ABFALLBESEITIGUNG__ENTSORGUNGSANLAGE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // JUSTIZBAUTEN__POLIZEI_JUSTIZ: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // JUSTIZBAUTEN__JUSTIZVOLLZUG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // JUSTIZBAUTEN__POLIZEI_JUSTIZ__GERICHT: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // JUSTIZBAUTEN__POLIZEI_JUSTIZ__POLIZEI: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // JUSTIZBAUTEN__POLIZEI_JUSTIZ__UNTERSUCHUNGSGEFAENGNIS: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EINFACH',
    // },
    // JUSTIZBAUTEN__POLIZEI_JUSTIZ__AUSBILDUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    JUSTIZBAUTEN__JUSTIZVOLLZUG__STRAFVOLLZUG: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // JUSTIZBAUTEN__JUSTIZVOLLZUG__WIEDEREINGLIEDERUNGSTAETTE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__TAGESHEIM_GESCHUTZE_WERKSTAETTE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__ARZTPRAXIS_AERZTEHAUS: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__SPITALBAUTEN: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // GESUNDHEITSBAUTEN__PFLEGE_REHA: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // GESUNDHEITSBAUTEN__SONDERKRANKENHAUS: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL__UNTERSUCHUNG_BEHANDLUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL__PFLEGE_BETTENTRAKT: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL__ADMINISTRATION: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL__SOZIALE_DIENSTE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL__LOGISTIK_INFRASTRUKTUR: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK__UNTERSUCHUNG_BEHANDLUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK__PFLEGE_BETTENTRAKT: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK__ADMINISTRATION: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK__SOZIALE_DIENSTE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK__LOGISTIK_INFRASTRUKTUR: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK__LEHRE_FORSCHUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK__LEHRE_FORSCHUNG__OHNE_LABOR: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK__LEHRE_FORSCHUNG__MIT_LABOR: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM__REHA_ETC: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM__REHA: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM__SANATORIUM: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__HEILBAD_SPEZIALINSTITUTION: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GESUNDHEITSBAUTEN__ERHOLUNG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // KULTUS__SAKRAL: {
    //   LOW: 'EINSTEINMAUERWERK_VERPUTZT',
    //   MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
    //   HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    // },
    KULTUS__KIRCHGEMEINDEHAEUSER: {
      LOW: 'EINSTEINMAUERWERK_VERPUTZT',
      MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
      HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    },
    // KULTUS__FRIEDHOF: {
    //   LOW: 'EINSTEINMAUERWERK_VERPUTZT',
    //   MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
    //   HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    // },
    // KULTUS__KLOESTER: {
    //   LOW: 'EINSTEINMAUERWERK_VERPUTZT',
    //   MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
    //   HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    // },
    // KULTUS__KAPELLE: {
    //   LOW: 'EINSTEINMAUERWERK_VERPUTZT',
    //   MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
    //   HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    // },
    // KULTUS__KIRCHE: {
    //   LOW: 'EINSTEINMAUERWERK_VERPUTZT',
    //   MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
    //   HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    // },
    // KULTUS__FRIEDHOF__ABDANKUNGSHALLE: {
    //   LOW: 'EINSTEINMAUERWERK_VERPUTZT',
    //   MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
    //   HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    // },
    // KULTUS__FRIEDHOF__KREMATORIUM: {
    //   LOW: 'EINSTEINMAUERWERK_VERPUTZT',
    //   MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
    //   HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    // },
    // KULTUR__AUSSTELLUNGEN_MUSEEN: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // KULTUR__FREIZEITGEB: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // KULTUR__HISTORISCHE_GEBAEUDE: {
    //   LOW: 'EINSTEINMAUERWERK_VERPUTZT',
    //   MEDIUM: 'EINSTEINMAUERWERK_VERPUTZT',
    //   HIGH: 'EINSTEINMAUERWERK_VERPUTZT',
    // },
    KULTUR__AUSSTELLUNGEN: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    KULTUR__MUSEEN: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // KULTUR__FREIZEITGEB_KULTUR_KONGRESS: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // KULTUR__WOHLFAHRT: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    KULTUR__KULTUR_GEMEINSCHAFTSZENTREN: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // KULTUR__KONGRESS: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    KULTUR__SAAL: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // KULTUR__FESTHALLEN: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // KULTUR__KONZERTE_THEATER: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // KULTUR__KINO_DISCO: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // KULTUR__KONZERTE: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // KULTUR__PAVILLONS: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // KULTUR__RADIO_FERNSEH_FILM: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // KULTUR__KINO: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // KULTUR__DISCO: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RESTUARATION: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EDEL',
      HIGH: 'MASSIVBAU_EDEL',
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__PERSONAL_RESTAURANT: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RASTSTAETTE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL_MOTEL: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE_ETC: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL: {
      LOW: 'MASSIVBAU_EINFACH',
      MEDIUM: 'MASSIVBAU_EINFACH',
      HIGH: 'MASSIVBAU_EDEL',
    },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__MOTEL: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE: {
      LOW: 'HOLZ_EINFACH',
      MEDIUM: 'HOLZ_EINFACH',
      HIGH: 'HOLZ_EDEL',
    },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__BERGHAUS: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__KINDERLAGER: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__STADIONANLAGEN: {
    //   LOW: 'SICHTBETONFASSADE_GIPS',
    //   MEDIUM: 'SICHTBETONFASSADE_GIPS',
    //   HIGH: 'SICHTBETONFASSADE_BACKSTEIN',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SONDERSPORTANLAGE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__FREIZEITZENTRUM: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__PAVILLONS: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE: {
      LOW: 'HOLZ_EINFACH',
      MEDIUM: 'HOLZ_EDEL',
      HIGH: 'HOLZ_EDEL',
    },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__MEHRZWECKHALLE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__EISSPORTHALLE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__HALLENBAD: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURN_SPORT_HALLE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TENNISHALLE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__REITHALLE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE__1_HALLE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE__2_HALLEN: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE__3_HALLEN: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__MEHRZWECKHALLE__1_HALLE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__MEHRZWECKHALLE__2_HALLEN: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__MEHRZWECKHALLE__3_HALLEN: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN__TRIBUNEBAU: {
    //   LOW: 'SICHTBETONFASSADE_GIPS',
    //   MEDIUM: 'SICHTBETONFASSADE_GIPS',
    //   HIGH: 'SICHTBETONFASSADE_BACKSTEIN',
    // },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___GARDEROBENGEBAEUDE: {
      LOW: 'HOLZ_EINFACH',
      MEDIUM: 'HOLZ_EINFACH',
      HIGH: 'HOLZ_EDEL',
    },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___KLUBHAEUSER: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN__BOOTSHAUS: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__PAERKE_GARTENANLAGEN__ZOOLOGISCHE_GARTENANLAGE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__PAERKE_GARTENANLAGEN__BOTANISCHE_GARTENANLAGE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__PAVILLONS__GARTEN: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // VERKEHRSBAUTEN__STRASSENVERKEHR: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    VERKEHRSBAUTEN__WERKHOEFE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    VERKEHRSBAUTEN__ZOLL: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__BAHN_ETC: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE: {
      LOW: 'KEINE_FASSADE',
      MEDIUM: 'KEINE_FASSADE',
      HIGH: 'KEINE_FASSADE',
    },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE: {
      LOW: 'NICHT_ISOLIERT_BETON',
      MEDIUM: 'NICHT_ISOLIERT_BETON',
      HIGH: 'NICHT_ISOLIERT_BETON',
    },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__PARKHAUS: {
    //   LOW: 'KEINE_FASSADE',
    //   MEDIUM: 'KEINE_FASSADE',
    //   HIGH: 'KEINE_FASSADE',
    // },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__PW_GARAGE: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__GEWERB_FAHRZEUGHALLE: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__UEBERDACHUNG: {
    //   LOW: 'KEINE_FASSADE',
    //   MEDIUM: 'KEINE_FASSADE',
    //   HIGH: 'KEINE_FASSADE',
    // },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PARKHAUS: {
      LOW: 'NICHT_ISOLIERT_BETON',
      MEDIUM: 'NICHT_ISOLIERT_BETON',
      HIGH: 'NICHT_ISOLIERT_BETON',
    },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PW_GARAGE: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__GEWERB_FAHRZEUGHALLE: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // VERKEHRSBAUTEN__STRASSENVERKEHR__VERWALTUNG: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__STRASSENVERKEHR__WERKSTATT: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__WERKHOEFE__DIENSTRAUM: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__WERKHOEFE__WERKSTATT: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE__TEMPERIERT: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE__NICHT_BEHEIZT: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // VERKEHRSBAUTEN__WERKHOEFE__LAGER_TEMPERIERT: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__WERKHOEFE__LAGER_NICHT_BEHEIZT: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // VERKEHRSBAUTEN__WERKHOEFE__OFFENE_LAGERHALLE: {
    //   LOW: 'KEINE_FASSADE',
    //   MEDIUM: 'KEINE_FASSADE',
    //   HIGH: 'KEINE_FASSADE',
    // },
    // VERKEHRSBAUTEN__WERKHOEFE__TANKSTELLE: {
    //   LOW: 'KEINE_FASSADE',
    //   MEDIUM: 'KEINE_FASSADE',
    //   HIGH: 'KEINE_FASSADE',
    // },
    // VERKEHRSBAUTEN__ZOLL__ZOLLGEBAEUDE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__ZOLL__UEBERDACHUNG: {
    //   LOW: 'KEINE_FASSADE',
    //   MEDIUM: 'KEINE_FASSADE',
    //   HIGH: 'KEINE_FASSADE',
    // },
    // VERKEHRSBAUTEN__BAHN: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__BUS: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__FLUG: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // VERKEHRSBAUTEN__BAHN__BAHNHOFGEB_EMPFANG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__BAHN__VERWALTUNG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__BAHN__STELLWERK: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__BAHN__BAHNDEPOT: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // VERKEHRSBAUTEN__BAHN__VERKEHRSPAVILLION_HALTESTELLE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__BAHN__UEBERDACHUNG: {
    //   LOW: 'KEINE_FASSADE',
    //   MEDIUM: 'KEINE_FASSADE',
    //   HIGH: 'KEINE_FASSADE',
    // },
    // VERKEHRSBAUTEN__BUS__BUSGEB_EMPFANG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__BUS__VERWALTUNG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__BUS__WERKSTATT: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EINFACH', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__BUS__BUSDEPOT: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // VERKEHRSBAUTEN__BUS__VERKEHRSPAVILLON_HALTESTELLE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__BUS__UEBERDACHUNG: {
    //   LOW: 'KEINE_FASSADE',
    //   MEDIUM: 'KEINE_FASSADE',
    //   HIGH: 'KEINE_FASSADE',
    // },
    // VERKEHRSBAUTEN__BUS__TANKSTELLE: { LOW: 'KEINE_FASSADE', MEDIUM: 'KEINE_FASSADE', HIGH: 'KEINE_FASSADE' },
    // VERKEHRSBAUTEN__SEILBAHN__SEILBAHNGEB_EMPFANG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__FLUG__PASSAGIERTERMINAL_EMPFANG: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__LUFT__FRACHTERMINAL: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__FLUG__VERWALTUNG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__FLUG__FLUGZEUGHALLE: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // VERKEHRSBAUTEN__SCHIFF__EMPFANG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__SCHIFF__VERWALTUNG: { LOW: 'MASSIVBAU_EINFACH', MEDIUM: 'MASSIVBAU_EDEL', HIGH: 'MASSIVBAU_EDEL' },
    // VERKEHRSBAUTEN__SCHIFF__WERKSTATT: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__SCHIFF__WERFTHALLE: {
    //   LOW: 'NICHT_ISOLIERT_HOLZ',
    //   MEDIUM: 'NICHT_ISOLIERT_HOLZ',
    //   HIGH: 'NICHT_ISOLIERT_HOLZ',
    // },
    // VERKEHRSBAUTEN__SCHIFF__VERKEHRSPAVILLON_HALTESTELLE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EDEL',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // VERKEHRSBAUTEN__SCHIFF__UEBERDACHUNG: {
    //   LOW: 'KEINE_FASSADE',
    //   MEDIUM: 'KEINE_FASSADE',
    //   HIGH: 'KEINE_FASSADE',
    // },
    // SCHUTZBAUTEN__MILITAER: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    SCHUTZBAUTEN__FEUERWEHR: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // SCHUTZBAUTEN__ARMEE_LOGISTIK_ZEUGHAUS: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // SCHUTZBAUTEN__ZIVILSCHUTZ: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__UNTERKUNFT: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__WIRTSCHAFTSGEBAEUDE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__SCHULUNGSGEBAEUDE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__VERWALTUNGSGEBAUEDE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__SPORTHALLE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EDEL', HIGH: 'HOLZ_EDEL' },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__SPORTHALLE__1_HALLE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__SPORTHALLE__2_HALLEN: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__KASERNE_AUSBILDUNG__SPORTHALLE__3_HALLEN: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EDEL',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__ARMEE_LOGISTIK_ZEUGHAUS__LAGERGEBAEUDE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__ARMEE_LOGISTIK_ZEUGHAUS__FAHRZEUGHALLE: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__ARMEE_LOGISTIK_ZEUGHAUS__WERKSTATT: {
    //   LOW: 'HOLZ_EINFACH',
    //   MEDIUM: 'HOLZ_EINFACH',
    //   HIGH: 'HOLZ_EDEL',
    // },
    // SCHUTZBAUTEN__ZIVILSCHUTZ__OEFFENTLICHE_ZIVILSCHUTZANLAGE_KOMMANDOPOSTEN: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // SCHUTZBAUTEN__ZIVILSCHUTZ__SANITAETSPOSTEN: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // SCHUTZBAUTEN__ZIVILSCHUTZ__GESCHUETZTE_OPERATIONSSTELLEN_NOTSPITAL: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // SCHUTZBAUTEN__ZIVILSCHUTZ__ZIVILSCHUTZ_AUSBILDUNGSZENTRUM: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // SCHUTZBAUTEN__ZIVILSCHUTZ__BUNKER_NICHT_BEHEIZT: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // SCHUTZBAUTEN__ZIVILSCHUTZ_LOGISTIKZENTRUM: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // SCHUTZBAUTEN__FEUERWEHR__DIENSTRAUM: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EDEL',
    // },
    // SCHUTZBAUTEN__FEUERWEHR__WERKSTATT: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // SCHUTZBAUTEN__FEUERWEHR__FAHRZEUGHALLE: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // SCHUTZBAUTEN__FEUERWEHR__LAGER_TEMPERIERT: { LOW: 'HOLZ_EINFACH', MEDIUM: 'HOLZ_EINFACH', HIGH: 'HOLZ_EDEL' },
    // SCHUTZBAUTEN__FEUERWEHR__BRANDSCHUTZSIMULATION: {
    //   LOW: 'NICHT_ISOLIERT_BETON',
    //   MEDIUM: 'NICHT_ISOLIERT_BETON',
    //   HIGH: 'NICHT_ISOLIERT_BETON',
    // },
    // NUTZUNGSSPEZ_ANLAGEN__GROSSKUECHE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EINFACH',
    // },
    // NUTZUNGSSPEZ_ANLAGEN__WAESCHEREIANALGE: {
    //   LOW: 'MASSIVBAU_EINFACH',
    //   MEDIUM: 'MASSIVBAU_EINFACH',
    //   HIGH: 'MASSIVBAU_EINFACH',
    // },
  };
}
