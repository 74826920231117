import { Injectable, inject } from '@angular/core';
import { Logger } from '@shared';
import { SliderIdentMappingService } from './slider-ident-mapping.service';
import { UsageQuantilesService } from './usage-type/usage-quantiles.service';
import { UsageSliderMappingService } from './usage-type/usage-slider-mapping.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MinergieStandard, RealEstateDTO, RealEstateUsageEmbeddable, SliderValue } from '@generated/generatedEntities';
import { RebuildEstateService } from '@app/core/services/rebuild/rebuild-estate.service';
import { SharedControllerFnService } from '@app/core/services/shared/shared-controller-fn.service';
import { GlobaltoastService } from '@app/core/services/GlobalToast/globaltoast.service';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _ from 'lodash';
import { UsageStandardUsed, UsageTypeKvUsed } from '@generated/extended';

const log = new Logger('SliderPresetService');

export interface SliderPresetEvent {
  presetMark: number;
  key: string;
  interval: number[];
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class SliderPresetService {
  private sliderIdentMappingService = inject(SliderIdentMappingService);
  private usageSliderMappingService = inject(UsageSliderMappingService);
  private usageQuantilesService = inject(UsageQuantilesService);
  private rebuildEstateService = inject(RebuildEstateService);
  private sharedControllerFnService = inject(SharedControllerFnService);
  private _globalToastService = inject(GlobaltoastService);
  private router = inject(Router);
  private presetEvent$: BehaviorSubject<SliderPresetEvent | null> = new BehaviorSubject<SliderPresetEvent | null>(null);

  constructor() {
    /**
     * this is to make sure that we clear the cached data from the behaviorSubject - otherwise the data gets emitted again but might be wrong.
     */
    this.router.events.pipe(untilDestroyed(this)).forEach((event) => {
      if (event instanceof NavigationStart) {
        this.presetEvent$.next(null);
      }
    });
  }

  presetEvent(): Observable<SliderPresetEvent | null> {
    return this.presetEvent$.asObservable();
  }

  private updatePresetEvent(value: SliderPresetEvent | null) {
    this.presetEvent$.next(value);
  }

  /*
   * Set default values of the sliders
   */
  setDefaultSliderModelValues(realEstate: any) {
    // Geometry and environment
    realEstate.quality.qualitaetDerGebaeudeform2 = 'WK5';

    // Material and front
    if (realEstate.metaData.withdrawalProductType == 'BUILDING_INSURANCE_VALUES') {
      realEstate.quality.qualitaetvorbereitungsarbeiten = null;
      realEstate.quality.qualitaetUmgebung = null;
    } else {
      realEstate.quality.qualitaetvorbereitungsarbeiten = 'WK5';
      realEstate.quality.qualitaetUmgebung = 'WK5';
    }
    realEstate.quality.qualitaetVorfertigung = 'WK4';
    realEstate.quality.qualitaetGrundstueck = 'WK5';
    realEstate.quality.fensterAnteil = 'WK5';

    // Technology
    realEstate.quality.qualitaetElektroAnlage = 'WK5';
    realEstate.quality.qualitaetHeizungsanlage = 'WK5';
    realEstate.quality.qualitaetSanitaeranlage = 'WK5';

    // Finishings
    realEstate.quality.qualitaetAusbau1 = 'WK5';
    realEstate.quality.qualitaetAusbau2 = 'WK5';
    log.debug('default SliderModelValues are set.');
    return realEstate;
  }

  /**
   * Additional amount for preaparatory work.
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {*}
   * @private
   */
  _amountPreparatoryWork(overgroundFloors: number, undergroundFloors: number): number {
    var amount = 0;
    if (undergroundFloors >= 2 && undergroundFloors < 3) {
      amount = Math.max(amount, 1);
    } else if (undergroundFloors >= 3 && undergroundFloors < 5) {
      amount = Math.max(amount, 2);
    } else if (undergroundFloors >= 5) {
      amount = Math.max(amount, 3);
    }
    if (overgroundFloors >= 4 && overgroundFloors < 7) {
      amount = Math.max(amount, 1);
    } else if (overgroundFloors >= 7 && overgroundFloors < 8) {
      amount = Math.max(amount, 2);
    } else if (overgroundFloors >= 8) {
      amount = Math.max(amount, 2);
    }
    return amount;
  }

  /**
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {number}
   * @private
   */
  _amountLot(overgroundFloors: number, undergroundFloors: number): number {
    var amount = 0;
    if (undergroundFloors >= 2) {
      amount = Math.max(amount, 1);
    }
    if (overgroundFloors >= 7) {
      amount = Math.max(amount, 1);
    }
    return amount;
  }

  /**
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {number}
   * @private
   */
  _amountBuildingForm(overgroundFloors: number, undergroundFloors: number): number {
    var amount = 0;
    if (undergroundFloors >= 2) {
      amount = Math.max(amount, 1);
    }
    return amount;
  }

  /**
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {number}
   * @private
   */
  _amountSanitary(overgroundFloors: number, undergroundFloors: number): number {
    var amount = 0;
    if (overgroundFloors >= 8) {
      amount = Math.max(amount, 1);
    }
    return amount;
  }

  /**
   * Rule from _Code_Input_Dokumente/Voreinstellungen Slider Drop down/Spezial-Regelwerk 2112220.xlsx
   * @returns {number}
   * @private
   */
  _amountElectric(presetElectric: number, minergieStandard: MinergieStandard, pv: boolean): number {
    var amount = 0;
    if (minergieStandard === 'MINERGIE' || minergieStandard === 'MINERGIE_P') {
      if (presetElectric < 4) {
        amount = Math.max(amount, 1);
      }
    } else if (minergieStandard === 'MINERGIE_A') {
      if (presetElectric < 4) {
        amount = Math.max(amount, 2);
      } else if (presetElectric === 4) {
        amount = Math.max(amount, 1);
      }
    }
    if (pv) {
      if (presetElectric < 4) {
        amount = Math.max(amount, 1);
      }
    }
    return amount;
  }

  /**
   * This function sets value marks for the right preset for the sliders.
   * @param preset_arr array containing wk positions (may be float [0, 7]) for each slider except Vorfertigung - sliders sorted by
   *  [Umgebung, Grundstueck, Vorbereitungsarbeiten, FensterAnteil, Gebaeudeform2, ElektroAnlage, Heizungsanlage,
   *  Sanitaeranlage, Ausbau1, Ausbau2]
   *  @param preset_i_arr array containing interval positions (array of floats) for each slider except Vorfertigung - sliders sorted like arr.
   * @param realEstateContainer real estate data
   * @param isPresetSliderModelValuesEnabled if true, the slider values will be set as well (to the same value as the value marks).
   * @param filterSliderPropertyNamesArr if present, contains an array of slider property names
   *  (such as qualitaetvorbereitungsarbeiten, qualitaetGrundstueck). If array is present, only presets
   *  the properties present in this array.
   */
  setSliderModelValuesArr(
    preset_arr: any,
    preset_i_arr: any,
    realEstateContainer: RealEstateDTO,
    isPresetSliderModelValuesEnabled: boolean,
    filterSliderPropertyNamesArr?: string[],
  ) {
    var q = realEstateContainer.quality;

    // complexity
    var fensterAnteil = preset_arr[3];
    var i_fensterAnteil = preset_i_arr[3];
    var qualitaetDerGebaeudeform2 = preset_arr[4];
    var i_qualitaetDerGebaeudeform2 = preset_i_arr[4];
    var qualitaetVorfertigung = 2;
    var i_qualitaetVorfertigung: number[] | undefined = undefined;
    var qualitaetvorbereitungsarbeiten: number | null = null;
    var i_qualitaetvorbereitungsarbeiten: number[] | null = null;
    if (realEstateContainer.metaData.withdrawalProductType !== 'BUILDING_INSURANCE_VALUES') {
      qualitaetvorbereitungsarbeiten = preset_arr[2];
      i_qualitaetvorbereitungsarbeiten = preset_i_arr[2];
    }
    var qualitaetGrundstueck = preset_arr[1];
    var i_qualitaetGrundstueck = preset_i_arr[1];

    // quality
    var qualitaetUmgebung: number | null = null;
    var i_qualitaetUmgebung: number[] | null = null;
    if (realEstateContainer.metaData.withdrawalProductType !== 'BUILDING_INSURANCE_VALUES') {
      qualitaetUmgebung = preset_arr[0];
      i_qualitaetUmgebung = preset_i_arr[0];
    }
    var qualitaetAusbau1 = preset_arr[8];
    var i_qualitaetAusbau1 = preset_i_arr[8];
    var qualitaetAusbau2 = preset_arr[9];
    var i_qualitaetAusbau2 = preset_i_arr[9];
    var qualitaetSanitaeranlage = preset_arr[7];
    var i_qualitaetSanitaeranlage = preset_i_arr[7];
    var qualitaetHeizungsanlage = preset_arr[6];
    var i_qualitaetHeizungsanlage = preset_i_arr[6];
    var qualitaetElektroAnlage = preset_arr[5];
    var i_qualitaetElektroAnlage = preset_i_arr[5];

    if (isPresetSliderModelValuesEnabled) {
      if (!!filterSliderPropertyNamesArr) {
        // only set if any additional amount is present and beq 1
        if (filterSliderPropertyNamesArr.includes('qualitaetvorbereitungsarbeiten')) {
          q.qualitaetvorbereitungsarbeiten =
            this.sliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten);
          q.qualitaetvorbereitungsarbeitenBackend = q.qualitaetvorbereitungsarbeiten as SliderValue;
        }
        if (filterSliderPropertyNamesArr.includes('qualitaetGrundstueck')) {
          q.qualitaetGrundstueck = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck);
          q.qualitaetGrundstueckBackend = q.qualitaetGrundstueck as SliderValue;
        }
        if (filterSliderPropertyNamesArr.includes('qualitaetDerGebaeudeform2')) {
          q.qualitaetDerGebaeudeform2 = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2);
          q.qualitaetDerGebaeudeform2Backend = q.qualitaetDerGebaeudeform2 as SliderValue;
        }
        if (filterSliderPropertyNamesArr.includes('qualitaetSanitaeranlage')) {
          q.qualitaetSanitaeranlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage);
          q.qualitaetSanitaeranlageBackend = q.qualitaetSanitaeranlage as SliderValue;
        }
        if (filterSliderPropertyNamesArr.includes('qualitaetElektroAnlage')) {
          q.qualitaetElektroAnlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage);
          q.qualitaetElektroAnlageBackend = q.qualitaetElektroAnlage as SliderValue;
        }
      } else {
        q.fensterAnteil = this.sliderIdentMappingService.getSliderIdentByMore(fensterAnteil);
        q.qualitaetDerGebaeudeform2 = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2);
        q.qualitaetVorfertigung = this.sliderIdentMappingService.getSliderIdentByLess(qualitaetVorfertigung);
        q.qualitaetvorbereitungsarbeiten =
          this.sliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten);
        // setTimeout(() => {
        q.qualitaetGrundstueck = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck);
        // });
        q.qualitaetUmgebung = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetUmgebung);
        q.qualitaetAusbau1 = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau1);
        q.qualitaetAusbau2 = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau2);
        q.qualitaetSanitaeranlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage);
        q.qualitaetHeizungsanlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetHeizungsanlage);
        // setTimeout(() => {
        q.qualitaetElektroAnlage = this.sliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage);

        //also setting backend values. if rebuild doesn't have all sliders visible, the backend values are not set - that's why we do it here.
        q.fensterAnteilBackend = q.fensterAnteil as SliderValue;
        q.qualitaetDerGebaeudeform2Backend = q.qualitaetDerGebaeudeform2 as SliderValue;
        q.qualitaetVorfertigungBackend = q.qualitaetVorfertigung as SliderValue;
        q.qualitaetvorbereitungsarbeitenBackend = q.qualitaetvorbereitungsarbeiten as SliderValue;
        q.qualitaetGrundstueckBackend = q.qualitaetGrundstueck as SliderValue;
        q.qualitaetUmgebungBackend = q.qualitaetUmgebung as SliderValue;
        q.qualitaetAusbau1Backend = q.qualitaetAusbau1 as SliderValue;
        q.qualitaetAusbau2Backend = q.qualitaetAusbau2 as SliderValue;
        q.qualitaetSanitaeranlageBackend = q.qualitaetSanitaeranlage as SliderValue;
        q.qualitaetHeizungsanlageBackend = q.qualitaetHeizungsanlage as SliderValue;
        q.qualitaetElektroAnlageBackend = q.qualitaetElektroAnlage as SliderValue;
        // });
      }
    }
    setTimeout(() => {
      this.setPresetMark(
        'realEstate.quality.fensterAnteil',
        this.sliderIdentMappingService.getSliderIdentByMore(fensterAnteil),
        i_fensterAnteil,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetDerGebaeudeform2',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2),
        i_qualitaetDerGebaeudeform2,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetVorfertigung',
        this.sliderIdentMappingService.getSliderIdentByLess(qualitaetVorfertigung),
        i_qualitaetVorfertigung != undefined ? i_qualitaetVorfertigung : [],
      );
      if (qualitaetvorbereitungsarbeiten) {
        this.setPresetMark(
          'realEstate.quality.qualitaetvorbereitungsarbeiten',
          this.sliderIdentMappingService.getSliderIdentByMore(qualitaetvorbereitungsarbeiten),
          i_qualitaetvorbereitungsarbeiten != undefined ? i_qualitaetvorbereitungsarbeiten : [],
        );
      }
      this.setPresetMark(
        'realEstate.quality.qualitaetGrundstueck',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetGrundstueck),
        i_qualitaetGrundstueck,
      );
      if (qualitaetUmgebung) {
        this.setPresetMark(
          'realEstate.quality.qualitaetUmgebung',
          this.sliderIdentMappingService.getSliderIdentByMore(qualitaetUmgebung),
          i_qualitaetUmgebung != undefined ? i_qualitaetUmgebung : [],
        );
      }
      this.setPresetMark(
        'realEstate.quality.qualitaetAusbau2',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau2),
        i_qualitaetAusbau2,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetAusbau1',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetAusbau1),
        i_qualitaetAusbau1,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetSanitaeranlage',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetSanitaeranlage),
        i_qualitaetSanitaeranlage,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetHeizungsanlage',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetHeizungsanlage),
        i_qualitaetHeizungsanlage,
      );
      this.setPresetMark(
        'realEstate.quality.qualitaetElektroAnlage',
        this.sliderIdentMappingService.getSliderIdentByMore(qualitaetElektroAnlage),
        i_qualitaetElektroAnlage,
      );
    });
  }

  setPresetMark(key: string, presetMark: number, interval: number[]) {
    const updatePresetEvent: SliderPresetEvent = {
      key: key,
      presetMark: presetMark,
      interval: interval,
    };
    // log.warn(updatePresetEvent);
    this.updatePresetEvent(updatePresetEvent);
  }

  correctUsages(usages: RealEstateUsageEmbeddable[]): RealEstateUsageEmbeddable[] {
    // Object.assign creates only shallow copy! This causes problems
    //const tempUsages = Object.assign([], usages) as RealEstateUsageEmbeddable[];
    const tempUsages = _.cloneDeep(usages);
    const ret = this.sharedControllerFnService.cleanUsages(tempUsages);
    if (typeof tempUsages !== 'undefined' && tempUsages.length > 0 && tempUsages[0].type && tempUsages[0].standard) {
      if (tempUsages.length === 1) {
        return tempUsages;
      } else if (
        tempUsages.length === 2 &&
        (tempUsages[1].type === undefined || tempUsages[1].type === null || !tempUsages[1].type) &&
        (tempUsages[1].standard === undefined || tempUsages[1].standard === null || !tempUsages[1].standard)
      ) {
        //standard doesn't matter
        return [tempUsages[0]];
      } else if (tempUsages.length > 1 && this.isUsagesReady(tempUsages)) {
        //$broadcast('showSliderPresetTooltip');
        this._globalToastService.showSliderPresetTooltip$.next(true);
        return tempUsages;
        // Use outcommented code below if you want to remove VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE from usages
        /*
        $rootScope.$broadcast('showSliderPresetTooltip');
        var correctedUsages = [];
        for (var usage in usages) {
            var usageObj = usages[usage];
            if (usageObj.type !== 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE') {
                correctedUsages.push(usageObj);
            }
        }
        return correctedUsages;
         */
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  arr_applyWeight(weightedArrEntry: any, entry: any, weight: any, isMatrix: boolean) {
    if (isMatrix) {
      // case entry and weightedArrEntry are of type Float32Array and have same size
      return weightedArrEntry.map((wae: any, i: string | number) => {
        if (weight === 0) {
          return wae;
        } else {
          return wae + weight * entry[i];
        }
      });
    } else {
      // case entry and weightedArrEntry are of type float
      if (weight === 0) {
        return weightedArrEntry;
      } else {
        return weightedArrEntry + weight * entry;
      }
    }
  }

  arr_div(weightedArrEntry: any, dividend: any, isMatrix: boolean) {
    if (isMatrix) {
      // case weightedArrEntry is of type Float32Array
      return weightedArrEntry.map((wae: any) => {
        return wae / dividend;
      });
    } else {
      return weightedArrEntry / dividend;
    }
  }

  arr_createArr(size: any, isMatrix: boolean): number[][] | Float32Array {
    if (isMatrix) {
      // in case the return array contains Float32Array's as elements
      return new Array(size).fill(new Float32Array(7));
    } else {
      return new Float32Array(size);
    }
  }

  getWeightedPresetValues(
    usages: RealEstateUsageEmbeddable[],
    realEstateContainer: RealEstateDTO,
    newValueMinergieStandard?: any,
    newValuePv?: any,
    newValueOvergroundFloors?: any,
    newValueUndergroundFloors?: any,
  ): number[] {
    return this.getWeightedPresetValuesNew(
      usages,
      realEstateContainer,
      newValueMinergieStandard,
      newValuePv,
      newValueOvergroundFloors,
      newValueUndergroundFloors,
    );
  }

  getWeightedPresetIntervals(usages: RealEstateUsageEmbeddable[]) {
    return this.getWeightedPresetIntervalsNew(usages, true);
  }

  //isIntervals -> only follow positive flow
  private getWeightedPresetIntervalsNew(usages: RealEstateUsageEmbeddable[], isIntervals: boolean): number[][] {
    // newValue
    var arrs = [];
    var weights = [];
    for (var usage in usages) {
      var usageObj = usages[usage];
      var arr: number[][] | undefined;
      if (usageObj != null && usageObj.type != null) {
        arr = this.usageQuantilesService.getMapping()[usageObj.type as UsageTypeKvUsed]!.slice();
      } else {
        //no interval present
        arr = undefined;
      }

      if (arr) {
        arrs.push(arr);
        if (usageObj.percentage) {
          if (
            usageObj.type === 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE' ||
            usageObj.type === 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE'
          ) {
            var p = parseInt(String(usageObj.percentage)) - 20;
            if (p < 0) {
              p = 0;
            }
            weights.push(p);
          } else {
            weights.push(parseInt(String(usageObj.percentage)));
          }
        }
      }
    }
    if (arrs.length > 0) {
      var weightedArr: number[][] = new Array(arrs[0].length).fill(new Float32Array(7));
      if (weights.length > 1) {
        var sumWeightsArr = new Float32Array(arrs[0].length).fill(0);
        for (var i = 0; i < weights.length; i++) {
          //FIXME replace type any
          var curArr: any = arrs[i];
          var curWeight = weights[i] / 100;
          if (curWeight === undefined) {
            curWeight = 1;
          }
          for (var j = 0; j < curArr.length; j++) {
            //weightedArr[j] = weightedArr[j] + curWeight * curArr[j];
            var curWeightInner = curWeight;
            if (!Array.isArray(curArr[j]) || !curArr[j].length) {
              // curArr: Array does not exist, is not an array or is empty
              // this is an expected case that occurs on usages the boxplot calculation should not be based on, e.g. Tiefgarage
              curWeightInner = 0;
              curArr[j] = new Float32Array(arrs[0].length).fill(0);
            }
            var w = curWeightInner;
            sumWeightsArr[j] += w;
            weightedArr[j] = this.arr_applyWeight(weightedArr[j], curArr[j], w, isIntervals);
          }
        }
        for (var i = 0; i < weightedArr.length; i++) {
          weightedArr[i] = this.arr_div(weightedArr[i], sumWeightsArr[i], isIntervals);
        }
      } else {
        weightedArr = arrs[0];
      }
      return weightedArr.map((a) => {
        return Array.from(a);
      });
    }
    return new Array<number[]>(0);
  }

  //remove isIntervals -> only follow negative flow
  private getWeightedPresetValuesNew(
    usages: RealEstateUsageEmbeddable[],
    realEstateContainer: RealEstateDTO,
    newValueMinergieStandard?: any,
    newValuePv?: any,
    newValueOvergroundFloors?: any,
    newValueUndergroundFloors?: any,
  ): number[] {
    var arrs: number[][] = [];
    var weights: number[] = [];
    for (var usage in usages) {
      var usageObj = usages[usage];
      var arr: number[] | undefined = undefined;
      if (!!usageObj.type && !!usageObj.standard) {
        arr = this.usageSliderMappingService
          .getMapping()
          [usageObj.type as UsageTypeKvUsed]![usageObj.standard as UsageStandardUsed]!.slice();
      }
      if (arr) {
        arrs.push(arr);
        if (usageObj.percentage) {
          if (
            usageObj.type === 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE' ||
            usageObj.type === 'VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE'
          ) {
            var p = parseInt(String(usageObj.percentage)) - 20;
            if (p < 0) {
              p = 0;
            }
            weights.push(p);
          } else {
            weights.push(parseInt(String(usageObj.percentage)));
          }
        }
      }
    }
    if (arrs.length > 0) {
      var weightedArr = new Float32Array(arrs[0].length);
      if (weights.length > 1) {
        var sumWeightsArr = new Float32Array(arrs[0].length).fill(0);
        for (var i = 0; i < weights.length; i++) {
          var curArr = arrs[i];
          var curWeight = weights[i] / 100;
          if (curWeight === undefined) {
            curWeight = 1;
          }
          for (var j = 0; j < curArr.length; j++) {
            //weightedArr[j] = weightedArr[j] + curWeight * curArr[j];
            var curWeightInner = curWeight;
            var w: number = isNaN(curArr[j]) ? 0 : curWeightInner;
            sumWeightsArr[j] += w;
            weightedArr[j] = this.arr_applyWeight(weightedArr[j], curArr[j], w, false);
          }
        }
        for (var i = 0; i < weightedArr.length; i++) {
          weightedArr[i] = this.arr_div(weightedArr[i], sumWeightsArr[i], false);
        }
      } else {
        weightedArr = new Float32Array(arrs[0]);
      }

      // apply special rules (Spezial-Regelwerk)
      // calc additional amounts
      var overgroundFloors = realEstateContainer.geometry.overgroundFloors;
      if (newValueOvergroundFloors !== undefined) {
        overgroundFloors = newValueOvergroundFloors;
      }
      var undergroundFloors = realEstateContainer.geometry.undergroundFloors;
      if (newValueUndergroundFloors !== undefined) {
        undergroundFloors = newValueUndergroundFloors;
      }
      var minergieStandard = realEstateContainer.quality.minergieStandard;
      if (newValueMinergieStandard != null) {
        minergieStandard = newValueMinergieStandard;
      }
      var pv = realEstateContainer.quality.pv;
      if (newValuePv != null) {
        pv = newValuePv;
      }

      if (!!weightedArr[2]) {
        var amountPrep = this._amountPreparatoryWork(overgroundFloors, undergroundFloors);
        weightedArr[2] = Math.min(weightedArr[2] + amountPrep, 7);
      }
      var amountLot = this._amountLot(overgroundFloors, undergroundFloors);
      weightedArr[1] = Math.min(weightedArr[1] + amountLot, 7);

      var amountBuildingForm = this._amountBuildingForm(overgroundFloors, undergroundFloors);
      weightedArr[4] = Math.min(weightedArr[4] + amountBuildingForm, 7);

      var amountSanitary = this._amountSanitary(overgroundFloors, undergroundFloors);
      weightedArr[7] = Math.min(weightedArr[7] + amountSanitary, 7);

      var amountElectric = this._amountElectric(weightedArr[5], minergieStandard, pv);
      weightedArr[5] = Math.min(weightedArr[5] + amountElectric, 7);
      return Array.from(weightedArr);
    }
    return [];
  }

  public requestPresetEvent(realEstateContainer: any, referenceStr: string): any {
    var usages = realEstateContainer.usages;
    if (
      realEstateContainer.metaData.withdrawalProductType === 'DAMAGE_COSTS' ||
      realEstateContainer.metaData.withdrawalProductType === 'REBUILD_COSTS'
    ) {
      usages = realEstateContainer.targetOverhaul.usages;
    }
    var correctUsages = this.correctUsages(usages);
    if (correctUsages !== undefined) {
      var preset_arr = this.getWeightedPresetValues(correctUsages, realEstateContainer);
      var preset_i_arr = this.getWeightedPresetIntervals(correctUsages);

      switch (referenceStr) {
        case 'realEstate.quality.fensterAnteil':
          var fensterAnteil = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[3]);
          var i_fensterAnteil = preset_i_arr[3];
          return { preset: fensterAnteil, interval: i_fensterAnteil };

        case 'realEstate.quality.qualitaetDerGebaeudeform2':
          var qualitaetDerGebaeudeform2 = preset_arr[4];
          var amountBuildingForm = this._amountBuildingForm(
            realEstateContainer.geometry.overgroundFloors,
            realEstateContainer.geometry.undergroundFloors,
          );
          qualitaetDerGebaeudeform2 = Math.min(qualitaetDerGebaeudeform2 + amountBuildingForm, 7);
          var i_qualitaetDerGebaeudeform2 = preset_i_arr[4];
          return {
            preset: this.sliderIdentMappingService.getSliderIdentByMore(qualitaetDerGebaeudeform2),
            interval: i_qualitaetDerGebaeudeform2,
          };

        case 'realEstate.quality.qualitaetVorfertigung':
          var qualitaetVorfertigung = this.sliderIdentMappingService.getSliderIdentByLess(2);
          var i_qualitaetVorfertigung = undefined;
          return { preset: qualitaetVorfertigung, interval: i_qualitaetVorfertigung };

        case 'realEstate.quality.qualitaetvorbereitungsarbeiten':
          var amountVorbereitungsarbeiten = this._amountPreparatoryWork(
            realEstateContainer.geometry.overgroundFloors,
            realEstateContainer.geometry.undergroundFloors,
          );
          var qualitaetvorbereitungsarbeiten = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[2]);
          qualitaetvorbereitungsarbeiten = Math.min(qualitaetvorbereitungsarbeiten + amountVorbereitungsarbeiten, 7);
          var i_qualitaetvorbereitungsarbeiten = preset_i_arr[2];
          return { preset: qualitaetvorbereitungsarbeiten, interval: i_qualitaetvorbereitungsarbeiten };

        case 'realEstate.quality.qualitaetGrundstueck':
          var amountLot = this._amountLot(
            realEstateContainer.geometry.overgroundFloors,
            realEstateContainer.geometry.undergroundFloors,
          );
          var qualitaetGrundstueck = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[1]);
          qualitaetGrundstueck = Math.min(qualitaetGrundstueck + amountLot, 7);
          var i_qualitaetGrundstueck = preset_i_arr[1];
          return { preset: qualitaetGrundstueck, interval: i_qualitaetGrundstueck };

        case 'realEstate.quality.qualitaetUmgebung':
          var qualitaetUmgebung = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[0]);
          var i_qualitaetUmgebung = preset_i_arr[0];
          return { preset: qualitaetUmgebung, interval: i_qualitaetUmgebung };

        case 'realEstate.quality.qualitaetAusbau1':
          var qualitaetAusbau1 = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[8]);
          var i_qualitaetAusbau1 = preset_i_arr[8];
          return { preset: qualitaetAusbau1, interval: i_qualitaetAusbau1 };

        case 'realEstate.quality.qualitaetAusbau2':
          var qualitaetAusbau2 = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[9]);
          var i_qualitaetAusbau2 = preset_i_arr[9];
          return { preset: qualitaetAusbau2, interval: i_qualitaetAusbau2 };

        case 'realEstate.quality.qualitaetSanitaeranlage':
          var qualitaetSanitaeranlage = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[7]);
          var i_qualitaetSanitaeranlage = preset_i_arr[7];
          return { preset: qualitaetSanitaeranlage, interval: i_qualitaetSanitaeranlage };

        case 'realEstate.quality.qualitaetHeizungsanlage':
          var qualitaetHeizungsanlage = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[6]);
          var i_qualitaetHeizungsanlage = preset_i_arr[6];
          return { preset: qualitaetHeizungsanlage, interval: i_qualitaetHeizungsanlage };

        case 'realEstate.quality.qualitaetElektroAnlage':
          var qualitaetElektroAnlage = this.sliderIdentMappingService.getSliderIdentByMore(preset_arr[5]);
          var i_qualitaetElektroAnlage = preset_i_arr[5];
          return { preset: qualitaetElektroAnlage, interval: i_qualitaetElektroAnlage };
        default:
          console.error('reference string not found for preset', referenceStr);
          return { preset: null, interval: null };
      }
    } else {
      return { preset: null, interval: null };
    }
  }

  isUsagesReady(usages: RealEstateUsageEmbeddable[]) {
    try {
      var totalPercentage = 0;
      for (var usage in usages) {
        var usageObj = usages[usage];
        // continue if usages not selected (empty row)
        if (
          usageObj.type !== undefined &&
          usageObj.type !== null &&
          usageObj.standard !== undefined &&
          usageObj.standard !== null
        ) {
          if (!usageObj.percentage) {
            // usages set, but no percentage set => usages is not ready
            return false;
          } else {
            totalPercentage += Number(usageObj.percentage);
          }
        }
      }
      return totalPercentage === 100;
    } catch (error) {
      console.error('Unexpected error in isUsageReady', usages, error);
      return false;
    }
  }
}
