import { Injectable, inject } from '@angular/core';
import { UsageToEnumResolverService } from './usage-to-enum-resolver.service';
import { Logger } from '@shared';
import { RealEstateUsageEmbeddable } from '@generated/generatedEntities';
import { UsageStandardUsed, UsageTypeKvUsed } from '@generated/extended';
// import roofTypePresets from './json_data/roof-presets-final.json';

const log = new Logger('RoofTypePresetService');
@Injectable({
  providedIn: 'root',
})
export class RoofTypePresetService {
  private usageToEnumResolverService = inject(UsageToEnumResolverService);
  mapGmdIdToMsReg = {
    '1': 3,
    '2': 3,
    '3': 3,
    '4': 3,
    '5': 3,
    '6': 3,
    '7': 3,
    '8': 3,
    '9': 3,
    '10': 3,
    '11': 3,
    '12': 3,
    '13': 3,
    '14': 3,
    '21': 3,
    '22': 3,
    '23': 3,
    '24': 3,
    '25': 3,
    '26': 3,
    '27': 3,
    '28': 3,
    '29': 3,
    '30': 3,
    '31': 3,
    '32': 3,
    '33': 3,
    '34': 3,
    '35': 3,
    '37': 3,
    '38': 3,
    '39': 3,
    '40': 3,
    '41': 3,
    '43': 3,
    '51': 2,
    '52': 1,
    '53': 2,
    '54': 1,
    '55': 2,
    '56': 2,
    '57': 2,
    '58': 2,
    '59': 2,
    '60': 2,
    '61': 2,
    '62': 1,
    '63': 2,
    '64': 1,
    '65': 2,
    '66': 1,
    '67': 2,
    '68': 2,
    '69': 1,
    '70': 2,
    '71': 2,
    '72': 2,
    '81': 2,
    '82': 1,
    '83': 1,
    '84': 1,
    '85': 1,
    '86': 2,
    '87': 1,
    '88': 2,
    '89': 2,
    '90': 2,
    '91': 2,
    '92': 2,
    '93': 2,
    '94': 1,
    '95': 2,
    '96': 1,
    '97': 1,
    '98': 2,
    '99': 2,
    '100': 2,
    '101': 2,
    '102': 2,
    '111': 5,
    '112': 5,
    '113': 5,
    '114': 5,
    '115': 5,
    '116': 5,
    '117': 5,
    '118': 5,
    '119': 5,
    '120': 5,
    '121': 5,
    '131': 2,
    '135': 2,
    '136': 2,
    '137': 2,
    '138': 2,
    '139': 2,
    '141': 2,
    '151': 2,
    '152': 2,
    '153': 2,
    '154': 2,
    '155': 2,
    '156': 2,
    '157': 2,
    '158': 2,
    '159': 2,
    '160': 2,
    '161': 2,
    '172': 5,
    '173': 5,
    '176': 4,
    '177': 5,
    '178': 5,
    '180': 4,
    '181': 5,
    '182': 5,
    '191': 1,
    '192': 2,
    '193': 1,
    '194': 5,
    '195': 1,
    '196': 5,
    '197': 1,
    '198': 5,
    '199': 1,
    '200': 1,
    '211': 3,
    '213': 4,
    '214': 4,
    '215': 4,
    '216': 4,
    '218': 4,
    '219': 4,
    '220': 4,
    '221': 4,
    '223': 4,
    '224': 4,
    '225': 4,
    '226': 4,
    '227': 4,
    '228': 4,
    '230': 4,
    '231': 4,
    '241': 2,
    '242': 2,
    '243': 2,
    '244': 2,
    '245': 2,
    '246': 2,
    '247': 2,
    '248': 2,
    '249': 2,
    '250': 2,
    '251': 2,
    '261': 1,
    '292': 3,
    '293': 2,
    '294': 4,
    '295': 2,
    '296': 4,
    '297': 5,
    '298': 4,
    '301': 11,
    '302': 11,
    '303': 11,
    '304': 11,
    '305': 11,
    '306': 11,
    '307': 1,
    '309': 11,
    '310': 11,
    '311': 11,
    '312': 11,
    '321': 11,
    '322': 11,
    '323': 11,
    '324': 11,
    '325': 11,
    '326': 11,
    '329': 11,
    '331': 11,
    '332': 11,
    '333': 11,
    '334': 11,
    '335': 11,
    '336': 11,
    '337': 11,
    '338': 11,
    '339': 11,
    '340': 11,
    '341': 11,
    '342': 11,
    '344': 11,
    '345': 11,
    '351': 1,
    '352': 1,
    '353': 1,
    '354': 1,
    '355': 1,
    '356': 1,
    '357': 14,
    '358': 1,
    '359': 1,
    '360': 1,
    '361': 1,
    '362': 1,
    '363': 1,
    '371': 7,
    '372': 7,
    '381': 9,
    '382': 11,
    '383': 9,
    '385': 11,
    '386': 11,
    '387': 9,
    '388': 9,
    '389': 9,
    '390': 9,
    '391': 9,
    '392': 7,
    '393': 9,
    '394': 11,
    '401': 9,
    '402': 9,
    '403': 1,
    '404': 9,
    '405': 9,
    '406': 9,
    '407': 9,
    '408': 9,
    '409': 9,
    '410': 9,
    '411': 9,
    '412': 9,
    '413': 9,
    '414': 9,
    '415': 9,
    '416': 9,
    '418': 9,
    '420': 9,
    '421': 9,
    '422': 9,
    '423': 9,
    '424': 9,
    '431': 12,
    '432': 7,
    '433': 12,
    '434': 7,
    '435': 7,
    '437': 7,
    '438': 12,
    '441': 7,
    '442': 12,
    '443': 7,
    '444': 12,
    '445': 7,
    '446': 7,
    '448': 7,
    '449': 12,
    '450': 12,
    '491': 11,
    '492': 11,
    '493': 11,
    '494': 11,
    '495': 11,
    '496': 11,
    '497': 11,
    '498': 11,
    '499': 11,
    '500': 11,
    '501': 11,
    '502': 11,
    '533': 9,
    '535': 1,
    '536': 1,
    '538': 9,
    '540': 1,
    '541': 1,
    '543': 1,
    '544': 1,
    '546': 1,
    '551': 1,
    '552': 9,
    '553': 1,
    '554': 9,
    '556': 9,
    '557': 1,
    '561': 13,
    '562': 8,
    '563': 13,
    '564': 13,
    '565': 13,
    '566': 8,
    '567': 13,
    '571': 10,
    '572': 10,
    '573': 10,
    '574': 10,
    '575': 10,
    '576': 10,
    '577': 10,
    '578': 10,
    '579': 10,
    '580': 10,
    '581': 10,
    '582': 10,
    '584': 10,
    '585': 10,
    '586': 10,
    '587': 10,
    '588': 10,
    '589': 10,
    '590': 10,
    '591': 10,
    '592': 10,
    '593': 10,
    '594': 10,
    '602': 3,
    '603': 3,
    '605': 3,
    '606': 3,
    '607': 3,
    '608': 3,
    '609': 3,
    '610': 3,
    '611': 3,
    '612': 3,
    '613': 14,
    '614': 3,
    '615': 3,
    '616': 3,
    '617': 3,
    '619': 3,
    '620': 3,
    '622': 3,
    '623': 3,
    '626': 3,
    '627': 1,
    '628': 3,
    '629': 3,
    '630': 1,
    '632': 3,
    '661': 11,
    '662': 11,
    '663': 1,
    '665': 11,
    '666': 11,
    '667': 11,
    '668': 11,
    '669': 11,
    '670': 11,
    '671': 11,
    '681': 12,
    '683': 12,
    '687': 12,
    '690': 12,
    '691': 12,
    '692': 12,
    '694': 12,
    '696': 12,
    '700': 12,
    '701': 12,
    '703': 12,
    '704': 12,
    '706': 12,
    '707': 12,
    '708': 12,
    '709': 12,
    '711': 12,
    '713': 12,
    '715': 12,
    '716': 12,
    '717': 12,
    '723': 12,
    '724': 12,
    '726': 12,
    '731': 7,
    '732': 7,
    '733': 7,
    '734': 11,
    '735': 7,
    '736': 7,
    '737': 7,
    '738': 7,
    '739': 7,
    '740': 7,
    '741': 7,
    '742': 7,
    '743': 7,
    '744': 7,
    '745': 7,
    '746': 7,
    '747': 7,
    '748': 7,
    '749': 7,
    '750': 7,
    '751': 7,
    '754': 11,
    '755': 11,
    '756': 7,
    '761': 8,
    '762': 8,
    '763': 8,
    '766': 8,
    '767': 8,
    '768': 8,
    '769': 8,
    '770': 8,
    '782': 10,
    '783': 10,
    '784': 10,
    '785': 10,
    '786': 10,
    '791': 13,
    '792': 13,
    '793': 13,
    '794': 13,
    '841': 13,
    '842': 13,
    '843': 13,
    '852': 14,
    '853': 14,
    '855': 14,
    '861': 1,
    '863': 3,
    '866': 3,
    '867': 3,
    '868': 3,
    '869': 3,
    '870': 1,
    '872': 3,
    '873': 3,
    '874': 3,
    '876': 3,
    '877': 14,
    '879': 14,
    '880': 14,
    '881': 3,
    '883': 3,
    '884': 3,
    '885': 8,
    '886': 3,
    '888': 14,
    '901': 14,
    '902': 14,
    '903': 14,
    '904': 14,
    '905': 14,
    '906': 14,
    '907': 14,
    '908': 14,
    '909': 14,
    '921': 8,
    '922': 8,
    '923': 8,
    '924': 8,
    '925': 8,
    '927': 8,
    '928': 8,
    '929': 8,
    '931': 8,
    '932': 8,
    '934': 8,
    '935': 8,
    '936': 8,
    '937': 8,
    '938': 8,
    '939': 8,
    '940': 8,
    '941': 8,
    '942': 8,
    '943': 8,
    '944': 8,
    '945': 8,
    '946': 8,
    '947': 8,
    '948': 8,
    '951': 9,
    '952': 11,
    '953': 11,
    '954': 11,
    '955': 9,
    '956': 9,
    '957': 9,
    '958': 9,
    '959': 11,
    '960': 11,
    '971': 11,
    '972': 11,
    '973': 11,
    '975': 11,
    '976': 11,
    '977': 11,
    '979': 11,
    '980': 11,
    '981': 11,
    '982': 11,
    '983': 11,
    '985': 11,
    '987': 11,
    '988': 11,
    '989': 11,
    '990': 11,
    '991': 11,
    '992': 11,
    '993': 11,
    '995': 11,
    '996': 11,
    '1001': 14,
    '1002': 14,
    '1004': 14,
    '1005': 14,
    '1007': 14,
    '1008': 14,
    '1009': 14,
    '1010': 14,
    '1021': 11,
    '1022': 11,
    '1023': 11,
    '1024': 6,
    '1025': 11,
    '1026': 6,
    '1030': 11,
    '1031': 11,
    '1032': 11,
    '1033': 6,
    '1037': 6,
    '1039': 11,
    '1040': 6,
    '1041': 11,
    '1051': 6,
    '1052': 6,
    '1053': 6,
    '1054': 6,
    '1055': 6,
    '1056': 9,
    '1057': 6,
    '1058': 6,
    '1059': 6,
    '1061': 6,
    '1062': 6,
    '1063': 6,
    '1064': 6,
    '1065': 6,
    '1066': 6,
    '1067': 6,
    '1068': 9,
    '1069': 9,
    '1081': 11,
    '1082': 11,
    '1083': 14,
    '1084': 11,
    '1085': 11,
    '1086': 14,
    '1088': 6,
    '1089': 11,
    '1091': 11,
    '1093': 11,
    '1094': 11,
    '1095': 11,
    '1097': 11,
    '1098': 14,
    '1099': 11,
    '1100': 11,
    '1102': 11,
    '1103': 11,
    '1104': 11,
    '1107': 14,
    '1121': 14,
    '1122': 14,
    '1123': 14,
    '1125': 14,
    '1126': 14,
    '1127': 14,
    '1128': 14,
    '1129': 14,
    '1130': 14,
    '1131': 14,
    '1132': 14,
    '1135': 14,
    '1136': 14,
    '1137': 14,
    '1139': 7,
    '1140': 7,
    '1142': 7,
    '1143': 14,
    '1145': 14,
    '1146': 14,
    '1147': 7,
    '1150': 14,
    '1151': 14,
    '1201': 12,
    '1202': 12,
    '1203': 12,
    '1204': 12,
    '1205': 12,
    '1206': 12,
    '1207': 12,
    '1208': 12,
    '1209': 12,
    '1210': 12,
    '1211': 12,
    '1212': 12,
    '1213': 12,
    '1214': 12,
    '1215': 12,
    '1216': 12,
    '1217': 12,
    '1218': 12,
    '1219': 12,
    '1220': 12,
    '1301': 12,
    '1311': 9,
    '1321': 5,
    '1322': 5,
    '1323': 5,
    '1331': 9,
    '1341': 5,
    '1342': 5,
    '1343': 12,
    '1344': 5,
    '1345': 5,
    '1346': 5,
    '1347': 5,
    '1348': 12,
    '1349': 5,
    '1361': 12,
    '1362': 9,
    '1363': 9,
    '1364': 9,
    '1365': 9,
    '1366': 9,
    '1367': 9,
    '1368': 12,
    '1369': 9,
    '1370': 12,
    '1371': 9,
    '1372': 9,
    '1373': 9,
    '1374': 9,
    '1375': 12,
    '1401': 11,
    '1402': 9,
    '1403': 11,
    '1404': 11,
    '1405': 11,
    '1406': 11,
    '1407': 11,
    '1501': 9,
    '1502': 9,
    '1503': 9,
    '1504': 9,
    '1505': 9,
    '1506': 9,
    '1507': 9,
    '1508': 9,
    '1509': 9,
    '1510': 9,
    '1511': 9,
    '1630': 12,
    '1631': 12,
    '1632': 12,
    '1701': 4,
    '1702': 4,
    '1703': 4,
    '1704': 4,
    '1705': 4,
    '1706': 4,
    '1707': 4,
    '1708': 4,
    '1709': 4,
    '1710': 4,
    '1711': 4,
    '2008': 11,
    '2009': 11,
    '2011': 11,
    '2016': 11,
    '2022': 11,
    '2025': 11,
    '2027': 11,
    '2029': 11,
    '2035': 11,
    '2038': 11,
    '2041': 11,
    '2043': 11,
    '2044': 11,
    '2045': 11,
    '2050': 11,
    '2051': 11,
    '2053': 11,
    '2054': 11,
    '2055': 11,
    '2061': 11,
    '2063': 11,
    '2066': 11,
    '2067': 11,
    '2068': 11,
    '2072': 11,
    '2079': 11,
    '2086': 11,
    '2087': 11,
    '2089': 11,
    '2096': 11,
    '2097': 11,
    '2099': 11,
    '2102': 11,
    '2111': 11,
    '2113': 11,
    '2114': 11,
    '2115': 11,
    '2116': 11,
    '2121': 9,
    '2122': 9,
    '2123': 9,
    '2124': 9,
    '2125': 9,
    '2128': 9,
    '2129': 9,
    '2130': 9,
    '2131': 9,
    '2134': 9,
    '2135': 9,
    '2137': 9,
    '2138': 9,
    '2140': 9,
    '2143': 9,
    '2145': 9,
    '2147': 9,
    '2148': 9,
    '2149': 9,
    '2152': 9,
    '2153': 9,
    '2155': 9,
    '2160': 9,
    '2162': 9,
    '2163': 9,
    '2171': 8,
    '2173': 8,
    '2174': 8,
    '2175': 8,
    '2177': 8,
    '2183': 8,
    '2185': 8,
    '2186': 8,
    '2189': 8,
    '2194': 8,
    '2196': 8,
    '2197': 8,
    '2198': 8,
    '2200': 8,
    '2206': 8,
    '2208': 8,
    '2211': 8,
    '2213': 8,
    '2216': 8,
    '2217': 8,
    '2220': 8,
    '2221': 8,
    '2225': 8,
    '2226': 8,
    '2228': 8,
    '2230': 8,
    '2233': 8,
    '2234': 8,
    '2235': 8,
    '2236': 8,
    '2250': 11,
    '2254': 11,
    '2257': 11,
    '2258': 11,
    '2259': 11,
    '2260': 11,
    '2261': 11,
    '2262': 11,
    '2265': 11,
    '2266': 11,
    '2271': 11,
    '2272': 11,
    '2274': 11,
    '2275': 11,
    '2276': 11,
    '2278': 11,
    '2284': 11,
    '2291': 9,
    '2292': 9,
    '2293': 9,
    '2294': 9,
    '2295': 9,
    '2296': 9,
    '2299': 9,
    '2300': 9,
    '2301': 9,
    '2302': 9,
    '2303': 9,
    '2304': 9,
    '2305': 9,
    '2306': 9,
    '2307': 9,
    '2308': 9,
    '2309': 9,
    '2321': 11,
    '2323': 11,
    '2325': 11,
    '2328': 11,
    '2333': 11,
    '2335': 11,
    '2336': 11,
    '2337': 11,
    '2338': 11,
    '2401': 8,
    '2402': 8,
    '2403': 8,
    '2404': 8,
    '2405': 8,
    '2406': 8,
    '2407': 8,
    '2408': 8,
    '2421': 12,
    '2422': 12,
    '2423': 12,
    '2424': 12,
    '2425': 12,
    '2426': 12,
    '2427': 12,
    '2428': 12,
    '2429': 12,
    '2445': 7,
    '2455': 7,
    '2456': 7,
    '2457': 7,
    '2461': 7,
    '2463': 7,
    '2464': 7,
    '2465': 7,
    '2471': 3,
    '2472': 3,
    '2473': 3,
    '2474': 3,
    '2475': 3,
    '2476': 3,
    '2477': 3,
    '2478': 3,
    '2479': 3,
    '2480': 3,
    '2481': 3,
    '2491': 8,
    '2492': 8,
    '2493': 8,
    '2495': 8,
    '2497': 8,
    '2498': 8,
    '2499': 8,
    '2500': 8,
    '2501': 8,
    '2502': 8,
    '2503': 8,
    '2511': 7,
    '2513': 7,
    '2514': 7,
    '2516': 7,
    '2517': 7,
    '2518': 7,
    '2519': 7,
    '2520': 7,
    '2523': 7,
    '2524': 7,
    '2525': 7,
    '2526': 7,
    '2527': 7,
    '2528': 7,
    '2529': 7,
    '2530': 7,
    '2532': 7,
    '2534': 7,
    '2535': 7,
    '2541': 7,
    '2542': 7,
    '2543': 9,
    '2544': 7,
    '2545': 7,
    '2546': 9,
    '2547': 7,
    '2548': 7,
    '2549': 7,
    '2550': 7,
    '2551': 7,
    '2553': 7,
    '2554': 7,
    '2555': 7,
    '2556': 7,
    '2571': 8,
    '2572': 8,
    '2573': 8,
    '2574': 8,
    '2575': 8,
    '2576': 8,
    '2578': 8,
    '2579': 8,
    '2580': 8,
    '2581': 8,
    '2582': 8,
    '2583': 8,
    '2584': 8,
    '2585': 8,
    '2586': 8,
    '2601': 7,
    '2611': 3,
    '2612': 3,
    '2613': 3,
    '2614': 3,
    '2615': 3,
    '2616': 3,
    '2617': 3,
    '2618': 3,
    '2619': 3,
    '2620': 3,
    '2621': 3,
    '2622': 3,
    '2701': 1,
    '2702': 1,
    '2703': 1,
    '2761': 2,
    '2762': 2,
    '2763': 2,
    '2764': 2,
    '2765': 2,
    '2766': 2,
    '2767': 2,
    '2768': 2,
    '2769': 2,
    '2770': 2,
    '2771': 2,
    '2772': 2,
    '2773': 2,
    '2774': 2,
    '2775': 2,
    '2781': 3,
    '2782': 3,
    '2783': 3,
    '2784': 3,
    '2785': 3,
    '2786': 3,
    '2787': 3,
    '2788': 3,
    '2789': 3,
    '2790': 3,
    '2791': 3,
    '2792': 3,
    '2793': 3,
    '2821': 3,
    '2822': 2,
    '2823': 3,
    '2824': 3,
    '2825': 3,
    '2826': 3,
    '2827': 3,
    '2828': 3,
    '2829': 3,
    '2830': 3,
    '2831': 2,
    '2832': 3,
    '2833': 3,
    '2834': 3,
    '2841': 3,
    '2842': 3,
    '2843': 3,
    '2844': 3,
    '2845': 3,
    '2846': 3,
    '2847': 3,
    '2848': 3,
    '2849': 3,
    '2850': 3,
    '2851': 3,
    '2852': 3,
    '2853': 3,
    '2854': 3,
    '2855': 3,
    '2856': 3,
    '2857': 3,
    '2858': 3,
    '2859': 3,
    '2860': 3,
    '2861': 3,
    '2862': 3,
    '2863': 3,
    '2864': 3,
    '2865': 3,
    '2866': 3,
    '2867': 3,
    '2868': 3,
    '2869': 3,
    '2881': 3,
    '2882': 3,
    '2883': 3,
    '2884': 3,
    '2885': 3,
    '2886': 3,
    '2887': 3,
    '2888': 3,
    '2889': 3,
    '2890': 3,
    '2891': 3,
    '2892': 3,
    '2893': 3,
    '2894': 3,
    '2895': 3,
    '2901': 4,
    '2903': 4,
    '2904': 4,
    '2914': 4,
    '2915': 4,
    '2917': 4,
    '2919': 4,
    '2920': 4,
    '2931': 4,
    '2932': 4,
    '2933': 4,
    '2936': 4,
    '2937': 4,
    '2938': 4,
    '2939': 4,
    '2951': 4,
    '2952': 4,
    '2953': 4,
    '2961': 4,
    '2962': 4,
    '2963': 4,
    '2964': 4,
    '2971': 4,
    '2972': 4,
    '2973': 4,
    '2974': 4,
    '3001': 9,
    '3002': 9,
    '3003': 9,
    '3004': 9,
    '3005': 9,
    '3006': 9,
    '3007': 9,
    '3021': 9,
    '3022': 9,
    '3023': 9,
    '3024': 9,
    '3025': 9,
    '3031': 9,
    '3032': 9,
    '3033': 9,
    '3034': 9,
    '3035': 9,
    '3036': 9,
    '3037': 9,
    '3038': 9,
    '3101': 14,
    '3102': 14,
    '3103': 14,
    '3104': 14,
    '3105': 14,
    '3111': 9,
    '3201': 6,
    '3202': 6,
    '3203': 6,
    '3204': 6,
    '3211': 6,
    '3212': 6,
    '3213': 6,
    '3214': 6,
    '3215': 6,
    '3216': 6,
    '3217': 9,
    '3218': 6,
    '3219': 6,
    '3231': 7,
    '3232': 7,
    '3233': 7,
    '3234': 7,
    '3235': 6,
    '3236': 6,
    '3237': 6,
    '3238': 7,
    '3251': 7,
    '3252': 7,
    '3253': 7,
    '3254': 7,
    '3255': 7,
    '3256': 7,
    '3271': 9,
    '3272': 9,
    '3273': 9,
    '3274': 9,
    '3275': 9,
    '3276': 9,
    '3291': 12,
    '3292': 12,
    '3293': 12,
    '3294': 12,
    '3295': 12,
    '3296': 12,
    '3297': 12,
    '3298': 12,
    '3311': 12,
    '3312': 5,
    '3313': 5,
    '3315': 5,
    '3316': 12,
    '3338': 5,
    '3339': 5,
    '3340': 5,
    '3341': 5,
    '3342': 5,
    '3352': 11,
    '3359': 11,
    '3360': 11,
    '3372': 11,
    '3374': 11,
    '3375': 11,
    '3378': 11,
    '3379': 11,
    '3392': 7,
    '3393': 7,
    '3394': 11,
    '3395': 11,
    '3401': 6,
    '3402': 6,
    '3405': 7,
    '3407': 7,
    '3408': 7,
    '3422': 6,
    '3423': 7,
    '3424': 7,
    '3426': 7,
    '3427': 7,
    '3441': 6,
    '3442': 6,
    '3443': 6,
    '3444': 6,
    '3506': 13,
    '3513': 13,
    '3514': 13,
    '3542': 13,
    '3543': 13,
    '3544': 13,
    '3551': 10,
    '3561': 10,
    '3572': 13,
    '3575': 13,
    '3581': 13,
    '3582': 13,
    '3603': 13,
    '3618': 13,
    '3619': 13,
    '3633': 14,
    '3637': 14,
    '3638': 14,
    '3640': 14,
    '3661': 14,
    '3662': 14,
    '3663': 14,
    '3668': 14,
    '3669': 14,
    '3670': 14,
    '3672': 13,
    '3673': 14,
    '3681': 14,
    '3695': 14,
    '3701': 14,
    '3703': 14,
    '3705': 14,
    '3707': 14,
    '3708': 14,
    '3711': 14,
    '3712': 14,
    '3713': 14,
    '3714': 14,
    '3721': 8,
    '3722': 8,
    '3723': 8,
    '3731': 8,
    '3732': 13,
    '3733': 8,
    '3734': 13,
    '3746': 13,
    '3752': 13,
    '3762': 13,
    '3764': 13,
    '3781': 10,
    '3782': 10,
    '3783': 10,
    '3784': 10,
    '3785': 10,
    '3786': 10,
    '3787': 10,
    '3788': 10,
    '3789': 10,
    '3790': 10,
    '3791': 10,
    '3792': 10,
    '3804': 12,
    '3805': 12,
    '3808': 12,
    '3810': 12,
    '3821': 12,
    '3822': 12,
    '3823': 12,
    '3831': 12,
    '3832': 12,
    '3834': 12,
    '3835': 12,
    '3837': 12,
    '3847': 13,
    '3851': 10,
    '3861': 13,
    '3862': 13,
    '3863': 13,
    '3871': 13,
    '3881': 13,
    '3882': 13,
    '3891': 13,
    '3901': 8,
    '3911': 13,
    '3921': 13,
    '3926': 13,
    '3932': 13,
    '3941': 8,
    '3945': 8,
    '3946': 8,
    '3947': 8,
    '3951': 8,
    '3952': 8,
    '3953': 8,
    '3954': 8,
    '3955': 8,
    '3961': 13,
    '3962': 13,
    '3972': 13,
    '3981': 13,
    '3982': 13,
    '3983': 13,
    '3985': 13,
    '3986': 13,
    '3987': 13,
    '3988': 13,
    '4001': 7,
    '4002': 7,
    '4003': 7,
    '4004': 7,
    '4005': 7,
    '4006': 7,
    '4007': 7,
    '4008': 7,
    '4009': 7,
    '4010': 7,
    '4012': 7,
    '4013': 7,
    '4021': 4,
    '4022': 3,
    '4023': 3,
    '4024': 4,
    '4026': 4,
    '4027': 4,
    '4028': 4,
    '4029': 4,
    '4030': 4,
    '4031': 3,
    '4032': 3,
    '4033': 3,
    '4034': 4,
    '4035': 3,
    '4037': 3,
    '4038': 4,
    '4039': 3,
    '4040': 4,
    '4041': 3,
    '4042': 4,
    '4044': 4,
    '4045': 4,
    '4046': 3,
    '4047': 4,
    '4048': 4,
    '4049': 4,
    '4061': 3,
    '4062': 3,
    '4063': 3,
    '4064': 5,
    '4065': 5,
    '4066': 3,
    '4067': 3,
    '4068': 5,
    '4071': 3,
    '4072': 3,
    '4073': 3,
    '4074': 3,
    '4075': 3,
    '4076': 5,
    '4077': 3,
    '4078': 5,
    '4079': 3,
    '4080': 5,
    '4081': 3,
    '4082': 5,
    '4083': 3,
    '4084': 3,
    '4091': 5,
    '4092': 5,
    '4093': 5,
    '4094': 3,
    '4095': 5,
    '4096': 3,
    '4097': 3,
    '4099': 5,
    '4100': 5,
    '4104': 5,
    '4105': 5,
    '4106': 5,
    '4107': 5,
    '4110': 5,
    '4111': 5,
    '4112': 5,
    '4114': 5,
    '4117': 5,
    '4120': 5,
    '4121': 5,
    '4122': 5,
    '4123': 5,
    '4124': 5,
    '4125': 5,
    '4131': 7,
    '4132': 7,
    '4133': 7,
    '4134': 7,
    '4135': 7,
    '4136': 7,
    '4137': 7,
    '4138': 7,
    '4139': 7,
    '4140': 7,
    '4141': 7,
    '4142': 7,
    '4143': 7,
    '4144': 7,
    '4145': 7,
    '4146': 7,
    '4147': 7,
    '4161': 3,
    '4163': 3,
    '4164': 3,
    '4165': 3,
    '4166': 3,
    '4167': 3,
    '4169': 3,
    '4170': 3,
    '4172': 3,
    '4173': 3,
    '4175': 3,
    '4176': 3,
    '4177': 3,
    '4179': 3,
    '4181': 3,
    '4182': 3,
    '4183': 3,
    '4184': 3,
    '4191': 7,
    '4192': 7,
    '4193': 5,
    '4194': 5,
    '4195': 7,
    '4196': 7,
    '4197': 7,
    '4198': 5,
    '4199': 7,
    '4200': 7,
    '4201': 7,
    '4202': 7,
    '4203': 7,
    '4204': 7,
    '4205': 5,
    '4206': 7,
    '4207': 7,
    '4208': 7,
    '4209': 7,
    '4210': 7,
    '4221': 5,
    '4222': 5,
    '4223': 5,
    '4224': 5,
    '4226': 5,
    '4227': 5,
    '4228': 5,
    '4229': 5,
    '4230': 5,
    '4231': 5,
    '4232': 5,
    '4233': 5,
    '4234': 5,
    '4235': 5,
    '4236': 5,
    '4237': 5,
    '4238': 5,
    '4239': 5,
    '4240': 5,
    '4251': 3,
    '4252': 3,
    '4253': 3,
    '4254': 3,
    '4255': 3,
    '4256': 3,
    '4257': 3,
    '4258': 3,
    '4259': 3,
    '4260': 3,
    '4261': 3,
    '4262': 3,
    '4263': 3,
    '4264': 3,
    '4271': 7,
    '4273': 7,
    '4274': 7,
    '4275': 7,
    '4276': 7,
    '4277': 7,
    '4279': 7,
    '4280': 7,
    '4281': 7,
    '4282': 7,
    '4283': 7,
    '4284': 7,
    '4285': 7,
    '4286': 7,
    '4287': 7,
    '4288': 7,
    '4289': 7,
    '4301': 5,
    '4302': 5,
    '4303': 5,
    '4304': 5,
    '4305': 5,
    '4306': 5,
    '4307': 5,
    '4308': 5,
    '4309': 5,
    '4310': 5,
    '4311': 5,
    '4312': 5,
    '4313': 5,
    '4314': 5,
    '4315': 5,
    '4316': 5,
    '4317': 5,
    '4318': 5,
    '4319': 5,
    '4320': 5,
    '4322': 5,
    '4323': 5,
    '4401': 9,
    '4406': 9,
    '4411': 9,
    '4416': 9,
    '4421': 6,
    '4426': 9,
    '4431': 9,
    '4436': 9,
    '4441': 9,
    '4446': 9,
    '4451': 9,
    '4461': 9,
    '4471': 9,
    '4476': 9,
    '4486': 9,
    '4495': 9,
    '4501': 5,
    '4506': 5,
    '4511': 9,
    '4536': 9,
    '4545': 9,
    '4546': 9,
    '4551': 5,
    '4561': 5,
    '4566': 5,
    '4571': 5,
    '4590': 5,
    '4591': 5,
    '4601': 5,
    '4606': 5,
    '4611': 5,
    '4616': 5,
    '4621': 5,
    '4641': 9,
    '4643': 9,
    '4646': 9,
    '4651': 9,
    '4656': 9,
    '4666': 9,
    '4671': 9,
    '4681': 9,
    '4683': 9,
    '4691': 9,
    '4696': 9,
    '4701': 9,
    '4711': 7,
    '4716': 7,
    '4721': 7,
    '4723': 7,
    '4724': 7,
    '4726': 7,
    '4741': 7,
    '4746': 7,
    '4751': 7,
    '4756': 5,
    '4761': 7,
    '4776': 7,
    '4781': 7,
    '4786': 7,
    '4791': 7,
    '4801': 9,
    '4806': 9,
    '4811': 5,
    '4816': 5,
    '4821': 5,
    '4826': 9,
    '4831': 5,
    '4841': 5,
    '4846': 5,
    '4851': 9,
    '4864': 9,
    '4871': 9,
    '4881': 5,
    '4891': 5,
    '4901': 5,
    '4911': 5,
    '4921': 5,
    '4941': 5,
    '4946': 5,
    '4951': 5,
    '5001': 8,
    '5002': 8,
    '5003': 8,
    '5009': 6,
    '5010': 8,
    '5017': 8,
    '5048': 12,
    '5049': 12,
    '5050': 12,
    '5061': 12,
    '5063': 12,
    '5064': 12,
    '5071': 12,
    '5072': 12,
    '5073': 12,
    '5076': 12,
    '5077': 12,
    '5078': 12,
    '5079': 12,
    '5091': 8,
    '5095': 8,
    '5096': 8,
    '5097': 8,
    '5102': 8,
    '5105': 8,
    '5108': 8,
    '5112': 8,
    '5113': 8,
    '5115': 8,
    '5117': 8,
    '5118': 8,
    '5120': 8,
    '5121': 8,
    '5125': 8,
    '5129': 8,
    '5131': 8,
    '5135': 8,
    '5136': 8,
    '5138': 8,
    '5141': 6,
    '5143': 6,
    '5144': 4,
    '5146': 6,
    '5148': 6,
    '5149': 6,
    '5151': 6,
    '5154': 4,
    '5160': 4,
    '5161': 6,
    '5162': 6,
    '5167': 6,
    '5171': 6,
    '5176': 6,
    '5178': 6,
    '5180': 6,
    '5181': 6,
    '5186': 6,
    '5187': 6,
    '5189': 6,
    '5192': 6,
    '5193': 6,
    '5194': 6,
    '5195': 4,
    '5196': 6,
    '5197': 4,
    '5198': 6,
    '5199': 6,
    '5200': 6,
    '5202': 6,
    '5203': 6,
    '5205': 6,
    '5206': 6,
    '5207': 6,
    '5208': 6,
    '5210': 6,
    '5212': 6,
    '5213': 6,
    '5214': 6,
    '5216': 6,
    '5219': 4,
    '5221': 6,
    '5222': 6,
    '5225': 6,
    '5226': 6,
    '5227': 6,
    '5230': 6,
    '5231': 6,
    '5233': 6,
    '5236': 6,
    '5237': 6,
    '5238': 6,
    '5242': 4,
    '5249': 4,
    '5250': 4,
    '5251': 4,
    '5254': 4,
    '5257': 4,
    '5260': 4,
    '5263': 4,
    '5266': 4,
    '5268': 4,
    '5269': 4,
    '5281': 12,
    '5287': 12,
    '5304': 8,
    '5307': 8,
    '5309': 8,
    '5310': 8,
    '5315': 8,
    '5317': 8,
    '5323': 8,
    '5324': 8,
    '5396': 8,
    '5397': 8,
    '5398': 8,
    '5401': 9,
    '5402': 9,
    '5403': 9,
    '5404': 9,
    '5405': 9,
    '5406': 9,
    '5407': 9,
    '5408': 9,
    '5409': 9,
    '5410': 9,
    '5411': 9,
    '5412': 9,
    '5413': 9,
    '5414': 9,
    '5415': 9,
    '5421': 2,
    '5422': 2,
    '5423': 2,
    '5424': 2,
    '5425': 2,
    '5426': 2,
    '5427': 2,
    '5428': 2,
    '5429': 2,
    '5430': 2,
    '5431': 2,
    '5432': 2,
    '5434': 2,
    '5435': 2,
    '5436': 2,
    '5437': 2,
    '5451': 11,
    '5456': 11,
    '5458': 11,
    '5464': 11,
    '5471': 3,
    '5472': 3,
    '5473': 3,
    '5474': 3,
    '5475': 3,
    '5476': 3,
    '5477': 3,
    '5478': 3,
    '5479': 3,
    '5480': 3,
    '5481': 3,
    '5482': 3,
    '5483': 3,
    '5484': 3,
    '5485': 3,
    '5486': 3,
    '5487': 3,
    '5488': 3,
    '5489': 3,
    '5490': 3,
    '5491': 3,
    '5492': 3,
    '5493': 3,
    '5494': 3,
    '5495': 3,
    '5496': 3,
    '5497': 3,
    '5498': 3,
    '5499': 3,
    '5500': 3,
    '5501': 3,
    '5503': 3,
    '5511': 3,
    '5512': 3,
    '5513': 3,
    '5514': 1,
    '5515': 1,
    '5516': 1,
    '5518': 3,
    '5520': 3,
    '5521': 3,
    '5522': 3,
    '5523': 1,
    '5527': 1,
    '5529': 3,
    '5530': 3,
    '5531': 3,
    '5533': 3,
    '5534': 3,
    '5535': 3,
    '5537': 3,
    '5539': 3,
    '5540': 3,
    '5541': 3,
    '5551': 5,
    '5552': 5,
    '5553': 5,
    '5554': 5,
    '5555': 5,
    '5556': 5,
    '5557': 5,
    '5559': 5,
    '5560': 5,
    '5561': 5,
    '5562': 5,
    '5563': 5,
    '5564': 5,
    '5565': 5,
    '5566': 5,
    '5568': 5,
    '5571': 5,
    '5581': 1,
    '5582': 1,
    '5583': 1,
    '5584': 1,
    '5585': 1,
    '5586': 1,
    '5587': 1,
    '5588': 1,
    '5589': 1,
    '5590': 1,
    '5591': 1,
    '5592': 1,
    '5601': 4,
    '5604': 4,
    '5606': 1,
    '5607': 4,
    '5609': 4,
    '5610': 4,
    '5611': 1,
    '5613': 4,
    '5621': 2,
    '5622': 2,
    '5623': 2,
    '5624': 1,
    '5625': 2,
    '5627': 1,
    '5628': 2,
    '5629': 2,
    '5631': 2,
    '5632': 2,
    '5633': 2,
    '5634': 2,
    '5635': 1,
    '5636': 2,
    '5637': 2,
    '5638': 2,
    '5639': 2,
    '5640': 2,
    '5642': 2,
    '5643': 2,
    '5644': 2,
    '5645': 2,
    '5646': 2,
    '5648': 1,
    '5649': 2,
    '5650': 2,
    '5651': 1,
    '5652': 2,
    '5653': 2,
    '5654': 2,
    '5655': 2,
    '5661': 11,
    '5663': 11,
    '5665': 11,
    '5669': 11,
    '5671': 11,
    '5673': 11,
    '5674': 11,
    '5675': 11,
    '5678': 11,
    '5680': 11,
    '5683': 11,
    '5684': 11,
    '5688': 11,
    '5690': 11,
    '5692': 11,
    '5693': 11,
    '5701': 2,
    '5702': 2,
    '5703': 2,
    '5704': 2,
    '5705': 2,
    '5706': 2,
    '5707': 2,
    '5708': 2,
    '5709': 2,
    '5710': 2,
    '5711': 2,
    '5712': 2,
    '5713': 2,
    '5714': 2,
    '5715': 2,
    '5716': 2,
    '5717': 2,
    '5718': 2,
    '5719': 2,
    '5720': 2,
    '5721': 2,
    '5722': 2,
    '5723': 2,
    '5724': 2,
    '5725': 2,
    '5726': 2,
    '5727': 2,
    '5728': 2,
    '5729': 2,
    '5730': 2,
    '5731': 2,
    '5732': 2,
    '5741': 3,
    '5742': 3,
    '5743': 3,
    '5744': 12,
    '5745': 5,
    '5746': 3,
    '5747': 3,
    '5748': 3,
    '5749': 3,
    '5750': 3,
    '5752': 3,
    '5754': 3,
    '5755': 3,
    '5756': 3,
    '5757': 3,
    '5758': 3,
    '5759': 3,
    '5760': 3,
    '5761': 3,
    '5762': 3,
    '5763': 3,
    '5764': 12,
    '5765': 3,
    '5766': 5,
    '5785': 11,
    '5788': 11,
    '5790': 11,
    '5792': 11,
    '5798': 11,
    '5799': 11,
    '5803': 11,
    '5804': 11,
    '5805': 11,
    '5806': 11,
    '5812': 11,
    '5813': 11,
    '5816': 11,
    '5817': 11,
    '5819': 11,
    '5821': 11,
    '5822': 11,
    '5827': 11,
    '5828': 11,
    '5830': 11,
    '5831': 11,
    '5841': 14,
    '5842': 14,
    '5843': 14,
    '5851': 2,
    '5852': 2,
    '5853': 2,
    '5854': 2,
    '5855': 2,
    '5856': 2,
    '5857': 2,
    '5858': 2,
    '5859': 2,
    '5860': 2,
    '5861': 2,
    '5862': 2,
    '5863': 2,
    '5871': 12,
    '5872': 12,
    '5873': 12,
    '5881': 4,
    '5882': 4,
    '5883': 4,
    '5884': 4,
    '5885': 4,
    '5886': 4,
    '5888': 4,
    '5889': 4,
    '5890': 4,
    '5891': 4,
    '5902': 5,
    '5903': 5,
    '5904': 5,
    '5905': 5,
    '5907': 5,
    '5908': 5,
    '5909': 5,
    '5910': 5,
    '5911': 5,
    '5912': 5,
    '5913': 5,
    '5914': 5,
    '5919': 5,
    '5921': 5,
    '5922': 5,
    '5923': 5,
    '5924': 5,
    '5925': 5,
    '5926': 5,
    '5928': 5,
    '5929': 5,
    '5930': 5,
    '5931': 5,
    '5932': 5,
    '5933': 5,
    '5934': 5,
    '5935': 5,
    '5937': 5,
    '5938': 5,
    '5939': 5,
    '6002': 10,
    '6004': 10,
    '6007': 10,
    '6008': 10,
    '6009': 10,
    '6010': 10,
    '6011': 10,
    '6021': 8,
    '6022': 8,
    '6023': 8,
    '6024': 8,
    '6025': 8,
    '6031': 10,
    '6032': 10,
    '6033': 10,
    '6034': 10,
    '6035': 10,
    '6036': 10,
    '6052': 13,
    '6054': 13,
    '6056': 13,
    '6057': 13,
    '6058': 13,
    '6061': 13,
    '6076': 13,
    '6077': 13,
    '6082': 8,
    '6083': 8,
    '6084': 8,
    '6087': 8,
    '6089': 8,
    '6090': 8,
    '6101': 13,
    '6102': 13,
    '6104': 13,
    '6109': 13,
    '6110': 13,
    '6111': 13,
    '6112': 13,
    '6113': 13,
    '6116': 13,
    '6117': 13,
    '6118': 13,
    '6119': 13,
    '6131': 10,
    '6132': 10,
    '6133': 10,
    '6134': 10,
    '6135': 10,
    '6136': 10,
    '6137': 10,
    '6139': 10,
    '6140': 10,
    '6141': 10,
    '6142': 10,
    '6151': 9,
    '6152': 9,
    '6153': 9,
    '6154': 9,
    '6155': 9,
    '6156': 9,
    '6157': 9,
    '6158': 9,
    '6159': 9,
    '6172': 10,
    '6173': 10,
    '6177': 13,
    '6181': 10,
    '6191': 10,
    '6192': 10,
    '6193': 10,
    '6194': 10,
    '6195': 10,
    '6197': 10,
    '6198': 10,
    '6199': 10,
    '6201': 10,
    '6202': 10,
    '6203': 10,
    '6204': 10,
    '6205': 10,
    '6211': 10,
    '6212': 10,
    '6213': 9,
    '6214': 10,
    '6215': 9,
    '6217': 9,
    '6218': 10,
    '6219': 10,
    '6220': 9,
    '6232': 10,
    '6235': 10,
    '6238': 10,
    '6239': 10,
    '6240': 10,
    '6241': 10,
    '6246': 8,
    '6248': 10,
    '6249': 10,
    '6250': 10,
    '6252': 10,
    '6253': 10,
    '6261': 8,
    '6263': 8,
    '6265': 8,
    '6266': 8,
    '6267': 8,
    '6281': 10,
    '6282': 10,
    '6283': 10,
    '6285': 10,
    '6286': 10,
    '6287': 10,
    '6288': 10,
    '6289': 10,
    '6290': 10,
    '6291': 10,
    '6292': 10,
    '6293': 10,
    '6294': 10,
    '6295': 10,
    '6296': 10,
    '6297': 10,
    '6298': 10,
    '6299': 10,
    '6300': 10,
    '6404': 8,
    '6407': 8,
    '6408': 8,
    '6412': 8,
    '6413': 8,
    '6416': 8,
    '6417': 8,
    '6421': 7,
    '6422': 7,
    '6423': 7,
    '6431': 7,
    '6432': 7,
    '6433': 7,
    '6434': 7,
    '6435': 7,
    '6436': 7,
    '6437': 7,
    '6451': 8,
    '6452': 8,
    '6453': 8,
    '6454': 8,
    '6455': 8,
    '6456': 8,
    '6458': 8,
    '6459': 8,
    '6461': 8,
    '6485': 8,
    '6487': 8,
    '6504': 12,
    '6511': 12,
    '6512': 12,
    '6601': 1,
    '6602': 1,
    '6603': 1,
    '6604': 1,
    '6605': 1,
    '6606': 1,
    '6607': 1,
    '6608': 1,
    '6609': 1,
    '6610': 1,
    '6611': 1,
    '6612': 1,
    '6613': 1,
    '6614': 1,
    '6615': 1,
    '6616': 1,
    '6617': 1,
    '6618': 1,
    '6619': 1,
    '6620': 1,
    '6621': 1,
    '6622': 1,
    '6623': 1,
    '6624': 1,
    '6625': 1,
    '6626': 1,
    '6627': 1,
    '6628': 1,
    '6629': 1,
    '6630': 1,
    '6631': 1,
    '6632': 1,
    '6633': 1,
    '6634': 1,
    '6635': 1,
    '6636': 1,
    '6637': 1,
    '6638': 1,
    '6639': 1,
    '6640': 1,
    '6641': 1,
    '6642': 1,
    '6643': 1,
    '6644': 1,
    '6645': 1,
    '6702': 9,
    '6703': 9,
    '6704': 9,
    '6706': 9,
    '6708': 9,
    '6709': 9,
    '6710': 9,
    '6711': 9,
    '6712': 9,
    '6713': 9,
    '6715': 9,
    '6716': 9,
    '6718': 9,
    '6719': 9,
    '6721': 9,
    '6722': 9,
    '6724': 9,
    '6729': 9,
    '6730': 9,
    '6741': 9,
    '6742': 9,
    '6743': 9,
    '6744': 9,
    '6745': 9,
    '6748': 9,
    '6750': 9,
    '6751': 9,
    '6753': 9,
    '6754': 9,
    '6757': 9,
    '6758': 9,
    '6759': 9,
    '6771': 9,
    '6773': 9,
    '6774': 9,
    '6775': 9,
    '6778': 9,
    '6781': 9,
    '6782': 9,
    '6783': 9,
    '6784': 9,
    '6785': 9,
    '6787': 9,
    '6789': 9,
    '6790': 9,
    '6792': 9,
    '6793': 9,
    '6800': 9,
    '6806': 9,
    '6807': 9,
    '6808': 9,
    '6809': 9,
    '6810': 9,
  };
  // from roof-presets-typologie-ms-region-final.json
  mapMsRegToRoofType = {
    '4': 'FLACHDACH',
    '1': 'FLACHDACH',
    '3': 'FLACHDACH',
    '6': 'FLACHDACH',
    '9': 'FLACHDACH',
    '5': 'FLACHDACH',
    '10': 'FLACHDACH',
    '2': 'FLACHDACH',
    '7': 'FLACHDACH',
    '11': 'FLACHDACH',
    '14': 'GENEIGTES_DACH',
    '8': 'FLACHDACH',
    '13': 'GENEIGTES_DACH',
    '12': 'FLACHDACH',
  };

  public mapDeToEnum(roofTypeDe: any): any {
    if (!!roofTypeDe) {
      if (roofTypeDe === 'FLACHDACH') {
        return 'FLATROOF';
      } else if (roofTypeDe === 'GENEIGTES_DACH') {
        return 'INCLINEDROOF';
      }
    }
  }

  public presetRoofType(usages: RealEstateUsageEmbeddable[], realEstateContainer: any) {
    var gmdNr = realEstateContainer.location.gmdNr;
    var roofPreset = undefined;
    if (!!gmdNr) {
      var ms_reg = this.getMapGmdIdToMsReg()[gmdNr];
      roofPreset = this.getMapMsRegToRoofType()[ms_reg];
    }

    if (!gmdNr || roofPreset === 'FLATROOF') {
      var roof = this.usageToEnumResolverService.resolve(usages, this.getMapping());
      roofPreset = roof;
    }
    if (!!roofPreset) {
      realEstateContainer.quality.roofTypes.splice(0, realEstateContainer.quality.roofTypes.length);
      realEstateContainer.quality.roofTypes.push(this.mapDeToEnum(roofPreset));
    }
    realEstateContainer.quality.roofTypes = ['FLATROOF'];
  }

  public getMapGmdIdToMsReg(): any {
    return this.mapGmdIdToMsReg;
  }

  public getMapMsRegToRoofType(): any {
    return this.mapMsRegToRoofType;
  }

  public getMapping(): {
    [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: 'GENEIGTES_DACH' | 'FLACHDACH' };
  } {
    return this.mapping;
  }

  private mapping: {
    [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: 'GENEIGTES_DACH' | 'FLACHDACH' };
  } = {
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHUTZBAUTEN__FEUERWEHR: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // HOCHBAU: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // NICHTWOHNBAUTEN__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // WOHNBAUTEN__WHG_MIN_2: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__EFH_REIHEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // WOHNBAUTEN__WOHNHEIME_HAEUSER: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__MFH: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__TERRASSENHAEUSER: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // WOHNBAUTEN__E_RFH__EFH: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // WOHNBAUTEN__E_RFH__REFH: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__ALTERSWOHNUNGEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__ALTERSHEIME: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__STUDENTENWOHNHAEUSER: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    WOHNBAUTEN__PERSONALHAEUSER: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__BEHINDERTE: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__KINDER: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    BUERO_HANDELSBAUTEN__BVB: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    BUERO_HANDELSBAUTEN__HANDELSBAU: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // LAGER_PRODUKTION: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    LANDWIRTSCHAFT__TITLE: { LOW: 'GENEIGTES_DACH', MEDIUM: 'GENEIGTES_DACH', HIGH: 'GENEIGTES_DACH' },
    JUSTIZBAUTEN__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    GESUNDHEITSBAUTEN__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    KULTUS__TITLE: { LOW: 'GENEIGTES_DACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    KULTUR__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    VERKEHRSBAUTEN__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHUTZBAUTEN__TITLE: { LOW: 'GENEIGTES_DACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    BUERO_HANDELSBAUTEN__BVB__BUERO: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__VERWALTUNG_BANK: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // BUERO_HANDELSBAUTEN__BVB__BEHOERDENHAEUSER: { LOW: 'GENEIGTES_DACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__GEMEINDEHAEUSER: {
      LOW: 'GENEIGTES_DACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS_STADTHAUS_STADTVERWALTUNG: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    SCHULBAUTEN__KINDERGAERTEN_HORTE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__VOLKSSCHULEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // SCHULBAUTEN__BERUFSSCHULEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__BIBLIOTHEKEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__FORSCHUNGSBAUTEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__BERUFSSCHULEN__BERUF: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__BERUFSSCHULEN__GYM: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // SCHULBAUTEN__LEHRE_OHNE_LABOR: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // SCHULBAUTEN__LEHRE_MIT_LABOR: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // SCHULBAUTEN__BIBLIOTHEKEN__BIBLIOTHEK: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // SCHULBAUTEN__BIBLIOTHEKEN__STAATSARCHIV: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__GROSSHANDEL: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE: { LOW: 'GENEIGTES_DACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__STILLES_GEWERBE_ATELIER: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE: {
      LOW: 'GENEIGTES_DACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    LAGERBAUTEN__TITLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    LAGER_PRODUKTION__PRODUKTION: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    LAGERBAUTEN_MEHRSTOECKIGE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    LAGERBAUTEN__VERTEILZENTRALEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_UNBEHEIZT: {
    //   LOW: 'GENEIGTES_DACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_BEHEIZT: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // LAGERBAUTEN__VERTEILZENTRALEN_NONFOOD: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    LANDWIRTSCHAFT__SCHEUNEN: { LOW: 'GENEIGTES_DACH', MEDIUM: 'GENEIGTES_DACH', HIGH: 'GENEIGTES_DACH' },
    // LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION: {
    //   LOW: 'GENEIGTES_DACH',
    //   MEDIUM: 'GENEIGTES_DACH',
    //   HIGH: 'GENEIGTES_DACH',
    // },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG: {
      LOW: 'GENEIGTES_DACH',
      MEDIUM: 'GENEIGTES_DACH',
      HIGH: 'GENEIGTES_DACH',
    },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG__GAERTNEREI: {
    //   LOW: 'GENEIGTES_DACH',
    //   MEDIUM: 'GENEIGTES_DACH',
    //   HIGH: 'GENEIGTES_DACH',
    // },
    // JUSTIZBAUTEN__JUSTIZVOLLZUG: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    JUSTIZBAUTEN__JUSTIZVOLLZUG__STRAFVOLLZUG: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // GESUNDHEITSBAUTEN__SPITALBAUTEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // GESUNDHEITSBAUTEN__PFLEGE_REHA: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // KULTUS__SAKRAL: { LOW: 'GENEIGTES_DACH', MEDIUM: 'GENEIGTES_DACH', HIGH: 'GENEIGTES_DACH' },
    KULTUS__KIRCHGEMEINDEHAEUSER: { LOW: 'GENEIGTES_DACH', MEDIUM: 'GENEIGTES_DACH', HIGH: 'GENEIGTES_DACH' },
    // KULTUS__FRIEDHOF: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // KULTUS__KAPELLE: { LOW: 'GENEIGTES_DACH', MEDIUM: 'GENEIGTES_DACH', HIGH: 'GENEIGTES_DACH' },
    // KULTUS__KIRCHE: { LOW: 'GENEIGTES_DACH', MEDIUM: 'GENEIGTES_DACH', HIGH: 'GENEIGTES_DACH' },
    // KULTUS__FRIEDHOF__KREMATORIUM: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // KULTUR__AUSSTELLUNGEN_MUSEEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // KULTUR__FREIZEITGEB: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    KULTUR__AUSSTELLUNGEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    KULTUR__MUSEEN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // KULTUR__FREIZEITGEB_KULTUR_KONGRESS: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // KULTUR__WOHLFAHRT: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    KULTUR__KULTUR_GEMEINSCHAFTSZENTREN: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    KULTUR__SAAL: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RESTUARATION: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__PERSONAL_RESTAURANT: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL_MOTEL: {
    //   LOW: 'GENEIGTES_DACH',
    //   MEDIUM: 'GENEIGTES_DACH',
    //   HIGH: 'GENEIGTES_DACH',
    // },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE_ETC: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL: {
      LOW: 'GENEIGTES_DACH',
      MEDIUM: 'GENEIGTES_DACH',
      HIGH: 'GENEIGTES_DACH',
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__MEHRZWECKHALLE: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__HALLENBAD: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___GARDEROBENGEBAEUDE: {
      LOW: 'FLACHDACH',
      MEDIUM: 'FLACHDACH',
      HIGH: 'FLACHDACH',
    },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    VERKEHRSBAUTEN__WERKHOEFE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    VERKEHRSBAUTEN__ZOLL: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__BAHN_ETC: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__PARKHAUS: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__PW_GARAGE: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__UEBERDACHUNG: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PARKHAUS: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PW_GARAGE: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // VERKEHRSBAUTEN__WERKHOEFE__DIENSTRAUM: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__WERKHOEFE__WERKSTATT: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE__TEMPERIERT: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE__NICHT_BEHEIZT: {
    //   LOW: 'FLACHDACH',
    //   MEDIUM: 'FLACHDACH',
    //   HIGH: 'FLACHDACH',
    // },
    // VERKEHRSBAUTEN__WERKHOEFE__LAGER_TEMPERIERT: { LOW: 'FLACHDACH', MEDIUM: 'FLACHDACH', HIGH: 'FLACHDACH' },
    // VERKEHRSBAUTEN__WERKHOEFE__LAGER_NICHT_BEHEIZT: {
    //   LOW: 'GENEIGTES_DACH',
    //   MEDIUM: 'GENEIGTES_DACH',
    //   HIGH: 'GENEIGTES_DACH',
    // },
  };
}
