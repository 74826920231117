import { Injectable } from '@angular/core';
import { RealEstateUsageEmbeddable } from '@generated/generatedEntities';
import { UsageStandardUsed, UsageTypeKvUsed } from '@generated/extended';

@Injectable({
  providedIn: 'root',
})
export class UsageToEnumResolverService {
  resolve(
    usages: RealEstateUsageEmbeddable[],
    mapping: { [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: any } },
  ) {
    try {
      var mapEnumToW: { [key: string]: any } = {};
      usages.forEach((u: RealEstateUsageEmbeddable) => {
        var r = (mapping as any)[u.type!][u.standard!];
        if (r) {
          if (r in mapEnumToW) {
            mapEnumToW[r] += u['percentage'];
          } else {
            if (!!u['percentage']) {
              mapEnumToW[r] = u['percentage'];
            } else {
              mapEnumToW[r] = 100;
            }
          }
        }
      });
      // return  Math.max(keyof mapEnumToW, function (o: number | string) {
      //     return mapEnumToW[o];
      // });
      const result1 = (Object.keys(mapEnumToW) as (keyof typeof mapEnumToW)[]).find((o) => {
        return mapEnumToW[o];
      });
      return result1!;
    } catch (e) {
      console.error('Could not reolve usages', usages, mapping, e);
      return null;
    }
  }
}
