import { Injectable, inject } from '@angular/core';
import { UsageToEnumResolverService } from './usage-to-enum-resolver.service';
import { Logger } from '@shared';
import { RealEstateUsageEmbeddable } from '@generated/generatedEntities';
import { UsageStandardUsed, UsageTypeKvUsed } from '@generated/extended';
// import minergieEcoPresets from './json_data/minergie-eco-presets-final.json';

const log = new Logger('MinergieEcoPresetService');
@Injectable({
  providedIn: 'root',
})
export class MinergieEcoPresetService {
  private usageToEnumResolverService = inject(UsageToEnumResolverService);
  minergieEcoPreset: any;

  public presetMinergieEco(usages: RealEstateUsageEmbeddable[], realEstateContainer: any) {
    this.minergieEcoPreset = this.usageToEnumResolverService.resolve(usages, this.getMapping());
    if (this.minergieEcoPreset === '1') {
      realEstateContainer.quality.energyStandard = 'MINERGIE_ECO';
    } else {
      realEstateContainer.quality.energyStandard = null;
    }
  }

  public getMapping(): { [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: number } } {
    return this.mapping;
  }

  private mapping: { [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: number } } = {
    // HOCHBAU: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    WOHNBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // NICHTWOHNBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // WOHNBAUTEN__WHG_MIN_2: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    WOHNBAUTEN__EFH_REIHEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // WOHNBAUTEN__WOHNHEIME_HAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    WOHNBAUTEN__MFH: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    WOHNBAUTEN__TERRASSENHAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // WOHNBAUTEN__E_RFH__EFH: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // WOHNBAUTEN__E_RFH__REFH: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    WOHNBAUTEN__ALTERSWOHNUNGEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    WOHNBAUTEN__ALTERSHEIME: { LOW: 0, MEDIUM: 0, HIGH: 1 },
    WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    WOHNBAUTEN__STUDENTENWOHNHAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    WOHNBAUTEN__PERSONALHAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__BEHINDERTE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__KINDER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__BVB: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHULBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__HANDELSBAU: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LAGER_PRODUKTION: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LANDWIRTSCHAFT__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    JUSTIZBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GESUNDHEITSBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    KULTUS__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    KULTUR__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    VERKEHRSBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHUTZBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__BVB__BUERO: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__VERWALTUNG_BANK: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__BVB__BEHOERDENHAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU: { LOW: 0, MEDIUM: 0, HIGH: 1 },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK__EDELROHBAU: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK__VOLLAUSBAU: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__GEMEINDEHAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS_STADTHAUS_STADTVERWALTUNG: {
      LOW: 0,
      MEDIUM: 0,
      HIGH: 0,
    },
    SCHULBAUTEN__KINDERGAERTEN_HORTE: { LOW: 0, MEDIUM: 0, HIGH: 1 },
    SCHULBAUTEN__VOLKSSCHULEN: { LOW: 0, MEDIUM: 0, HIGH: 1 },
    // SCHULBAUTEN__BERUFSSCHULEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN: { LOW: 0, MEDIUM: 0, HIGH: 1 },
    SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // SCHULBAUTEN__AULA: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHULBAUTEN__FORSCHUNGSBAUTEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHULBAUTEN__BERUFSSCHULEN__BERUF: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHULBAUTEN__BERUFSSCHULEN__GYM: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // SCHULBAUTEN__LEHRE_OHNE_LABOR: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // SCHULBAUTEN__LEHRE_MIT_LABOR: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHULBAUTEN__BIBLIOTHEKEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // SCHULBAUTEN__BIBLIOTHEKEN__BIBLIOTHEK: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // SCHULBAUTEN__BIBLIOTHEKEN__STAATSARCHIV: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR__MEDIUM: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR__HIGH: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__GROSSHANDEL: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__STILLES_GEWERBE_ATELIER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // BUERO_HANDELSBAUTEN__GROSSHANDEL__GROSSHANDELSBAU_GROSSMARKT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LAGERBAUTEN__TITLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LAGER_PRODUKTION__PRODUKTION: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LAGERBAUTEN_MEHRSTOECKIGE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LAGERBAUTEN__VERTEILZENTRALEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_UNBEHEIZT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_BEHEIZT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN__OFFEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LAGERBAUTEN__VERTEILZENTRALEN_NONFOOD: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LAGERBAUTEN__VERTEILZENTRALEN_FOOD: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LANDWIRTSCHAFT__SCHEUNEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG__GAERTNEREI: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG__GEWAECHSHAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // JUSTIZBAUTEN__JUSTIZVOLLZUG: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    JUSTIZBAUTEN__JUSTIZVOLLZUG__STRAFVOLLZUG: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // GESUNDHEITSBAUTEN__SPITALBAUTEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // GESUNDHEITSBAUTEN__PFLEGE_REHA: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUS__SAKRAL: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    KULTUS__KIRCHGEMEINDEHAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUS__FRIEDHOF: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUS__KAPELLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUS__KIRCHE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUS__FRIEDHOF__ABDANKUNGSHALLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUS__FRIEDHOF__KREMATORIUM: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUR__AUSSTELLUNGEN_MUSEEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUR__FREIZEITGEB: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    KULTUR__AUSSTELLUNGEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    KULTUR__MUSEEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUR__FREIZEITGEB_KULTUR_KONGRESS: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // KULTUR__WOHLFAHRT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    KULTUR__KULTUR_GEMEINSCHAFTSZENTREN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    KULTUR__SAAL: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RESTUARATION: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__PERSONAL_RESTAURANT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL_MOTEL: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE_ETC: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__MEHRZWECKHALLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__HALLENBAD: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___GARDEROBENGEBAEUDE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___KLUBHAEUSER: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    VERKEHRSBAUTEN__WERKHOEFE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    VERKEHRSBAUTEN__ZOLL: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__BAHN_ETC: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__PARKHAUS: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__PW_GARAGE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__GEWERB_FAHRZEUGHALLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__UEBERDACHUNG: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PARKHAUS: { LOW: 0, MEDIUM: 0, HIGH: 0 },

    SCHUTZBAUTEN__FEUERWEHR: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PW_GARAGE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__GEWERB_FAHRZEUGHALLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__WERKHOEFE__DIENSTRAUM: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__WERKHOEFE__WERKSTATT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE__TEMPERIERT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE__NICHT_BEHEIZT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__WERKHOEFE__LAGER_TEMPERIERT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__WERKHOEFE__LAGER_NICHT_BEHEIZT: { LOW: 0, MEDIUM: 0, HIGH: 0 },
    // VERKEHRSBAUTEN__WERKHOEFE__OFFENE_LAGERHALLE: { LOW: 0, MEDIUM: 0, HIGH: 0 },
  };
}
