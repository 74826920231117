import { Injectable, inject } from '@angular/core';
import { UsageToEnumResolverService } from './usage-to-enum-resolver.service';
import { Logger } from '@shared';
import { MinergieStandard, RealEstateDTO, RealEstateUsageEmbeddable } from '@generated/generatedEntities';
import { UsageStandardUsed, UsageTypeKvUsed } from '@generated/extended';

const log = new Logger('MinergieTypePresetService');
@Injectable({
  providedIn: 'root',
})
export class MinergieTypePresetService {
  private usageToEnumResolverService = inject(UsageToEnumResolverService);
  //TODO better presets due to age of object?
  public presetMinergieType(usages: RealEstateUsageEmbeddable[], realEstate: RealEstateDTO) {
    var minergiePreset = this.usageToEnumResolverService.resolve(usages, this.getMapping());
    const year = realEstate.constructionYear || realEstate.lastRebuildYear;
    if (year !== null && year !== undefined && year < 2000 && minergiePreset != 'NONE') {
      realEstate.quality.minergieStandard = 'NONE';
    } else if (minergiePreset != null) {
      realEstate.quality.minergieStandard = minergiePreset as MinergieStandard;
    }
  }

  public getMapping(): { [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: MinergieStandard } } {
    return this.mapping;
  }

  public mapping: { [index in UsageTypeKvUsed]: { [standard in UsageStandardUsed]: MinergieStandard } } = {
    // HOCHBAU: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    WOHNBAUTEN__TITLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // NICHTWOHNBAUTEN__TITLE: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // WOHNBAUTEN__WHG_MIN_2: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    WOHNBAUTEN__EFH_REIHEN: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // WOHNBAUTEN__WOHNHEIME_HAEUSER: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    WOHNBAUTEN__MFH: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    WOHNBAUTEN__TERRASSENHAEUSER: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // WOHNBAUTEN__E_RFH__EFH: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // WOHNBAUTEN__E_RFH__REFH: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    WOHNBAUTEN__ALTERSWOHNUNGEN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    WOHNBAUTEN__ALTERSHEIME: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'NONE' },
    WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    WOHNBAUTEN__STUDENTENWOHNHAEUSER: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    WOHNBAUTEN__PERSONALHAEUSER: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__BEHINDERTE: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // WOHNBAUTEN__HEIMSTAETTEN_KINDERHEIME_JUGENDHEIME__KINDER: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    BUERO_HANDELSBAUTEN__BVB: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__TITLE: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    BUERO_HANDELSBAUTEN__HANDELSBAU: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LAGER_PRODUKTION: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LANDWIRTSCHAFT__TITLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    JUSTIZBAUTEN__TITLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    GESUNDHEITSBAUTEN__TITLE: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    KULTUS__TITLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    KULTUR__TITLE: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__TITLE: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    VERKEHRSBAUTEN__TITLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    SCHUTZBAUTEN__TITLE: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    BUERO_HANDELSBAUTEN__BVB__BUERO: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__VERWALTUNG_BANK: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // BUERO_HANDELSBAUTEN__BVB__BEHOERDENHAEUSER: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    BUERO_HANDELSBAUTEN__BVB__BUERO__EDELROHBAU: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    BUERO_HANDELSBAUTEN__BVB__BUERO__VOLLAUSBAU: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK__EDELROHBAU: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // BUERO_HANDELSBAUTEN__BUEROBAUTEN__TECHNOPARK__VOLLAUSBAU: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__VERWALTUNG: { LOW: 'MINERGIE', MEDIUM: 'NONE', HIGH: 'NONE' },
    BUERO_HANDELSBAUTEN__BVB__VERWALTUNG_BANK__BANK: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__GEMEINDEHAEUSER: {
      LOW: 'NONE',
      MEDIUM: 'MINERGIE',
      HIGH: 'MINERGIE',
    },
    BUERO_HANDELSBAUTEN__BUEROBAUTEN__BEHOERDENHAEUSER__RATHAUS_STADTHAUS_STADTVERWALTUNG: {
      LOW: 'NONE',
      MEDIUM: 'MINERGIE',
      HIGH: 'MINERGIE',
    },
    SCHULBAUTEN__KINDERGAERTEN_HORTE: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__VOLKSSCHULEN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // SCHULBAUTEN__BERUFSSCHULEN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__HEILPAEDAGOGISCHE_SONDERSCHULEN: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__HOCHSCHULEN_UNIVERSITAETEN: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'NONE' },
    // SCHULBAUTEN__AULA: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__FORSCHUNGSBAUTEN: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__BERUFSSCHULEN__BERUF: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__BERUFSSCHULEN__GYM: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // SCHULBAUTEN__LEHRE_OHNE_LABOR: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // SCHULBAUTEN__LEHRE_MIT_LABOR: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__BIBLIOTHEKEN: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // SCHULBAUTEN__BIBLIOTHEKEN__BIBLIOTHEK: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // SCHULBAUTEN__BIBLIOTHEKEN__STAATSARCHIV: { LOW: 'MINERGIE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },

    SCHULBAUTEN__FORSCHUNGSBAUTEN_OHNE_LABOR: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR__MEDIUM: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // SCHULBAUTEN__FORSCHUNGSBAUTEN_MIT_LABOR__HIGH: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__GROSSHANDEL: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__LADENBAU: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // BUERO_HANDELSBAUTEN__HANDELSBAU__EINZELHANDEL__STILLES_GEWERBE_ATELIER: {
    //   LOW: 'NONE',
    //   MEDIUM: 'NONE',
    //   HIGH: 'NONE',
    // },
    BUERO_HANDELSBAUTEN__GROSSHANDEL__WARENHAUS_EINKAUFSZENTRUM: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // BUERO_HANDELSBAUTEN__GROSSHANDEL__GROSSHANDELSBAU_GROSSMARKT: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    BUERO_HANDELSBAUTEN__HANDELSBAU__MARKT_MESSE__MARKT_MESSEHALLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LAGERBAUTEN__TITLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LAGER_PRODUKTION__PRODUKTION: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LAGERBAUTEN_MEHRSTOECKIGE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LAGERBAUTEN__VERTEILZENTRALEN: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_UNBEHEIZT: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN_BEHEIZT: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LAGERBAUTEN__INDUSTRIEBAUTEN_GEWERBEBAUTEN__LAGERHALLEN__OFFEN: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LAGERBAUTEN__VERTEILZENTRALEN_NONFOOD: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LAGERBAUTEN__VERTEILZENTRALEN_FOOD: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LAGER_PRODUKTION__PRODUKTION__INDUSTRIEHALLEN: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LAGER_PRODUKTION__PRODUKTION__PRODUKTIONSBAUTEN: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LANDWIRTSCHAFT__SCHEUNEN: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    LANDWIRTSCHAFT__GEB_TIERHALTUNG_PRODUKTION__STALLUNG: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG__GAERTNEREI: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // LANDWIRTSCHAFT__PFLANZENHALTUNG__GEWAECHSHAEUSER: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // JUSTIZBAUTEN__JUSTIZVOLLZUG: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    JUSTIZBAUTEN__JUSTIZVOLLZUG__STRAFVOLLZUG: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // GESUNDHEITSBAUTEN__SPITALBAUTEN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // GESUNDHEITSBAUTEN__PFLEGE_REHA: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    GESUNDHEITSBAUTEN__KRANKENHAUS_SPITAL: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    GESUNDHEITSBAUTEN__UNIVERSITAETSKLINIK: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    GESUNDHEITSBAUTEN__PFLEGE_REHA__PFLEGEHEIM: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // KULTUS__SAKRAL: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    KULTUS__KIRCHGEMEINDEHAEUSER: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // KULTUS__FRIEDHOF: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // KULTUS__KAPELLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // KULTUS__KIRCHE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // KULTUS__FRIEDHOF__ABDANKUNGSHALLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // KULTUS__FRIEDHOF__KREMATORIUM: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    // KULTUR__AUSSTELLUNGEN_MUSEEN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // KULTUR__FREIZEITGEB: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    KULTUR__AUSSTELLUNGEN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    KULTUR__MUSEEN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // KULTUR__FREIZEITGEB_KULTUR_KONGRESS: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // KULTUR__WOHLFAHRT: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    KULTUR__KULTUR_GEMEINSCHAFTSZENTREN: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    KULTUR__SAAL: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__RESTUARATION: {
      LOW: 'NONE',
      MEDIUM: 'NONE',
      HIGH: 'MINERGIE',
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__VERPFLEGUNGSEINRICHTUNG__PERSONAL_RESTAURANT: {
      LOW: 'NONE',
      MEDIUM: 'NONE',
      HIGH: 'MINERGIE',
    },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL_MOTEL: {
    //   LOW: 'NONE',
    //   MEDIUM: 'MINERGIE',
    //   HIGH: 'MINERGIE',
    // },
    // GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE_ETC: {
    //   LOW: 'NONE',
    //   MEDIUM: 'MINERGIE',
    //   HIGH: 'MINERGIE',
    // },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HOTEL: {
      LOW: 'NONE',
      MEDIUM: 'MINERGIE',
      HIGH: 'MINERGIE',
    },
    GASTGEWERBE_FREMDENVERKEHRSBAUTEN__BEHERBERGUNGSSTAETTE__HERBERGE: {
      LOW: 'NONE',
      MEDIUM: 'MINERGIE',
      HIGH: 'MINERGIE',
    },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN: {
    //   LOW: 'NONE',
    //   MEDIUM: 'MINERGIE',
    //   HIGH: 'MINERGIE',
    // },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'MINERGIE' },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__TURN_SPORTHALLEN__TURNHALLE: {
      LOW: 'NONE',
      MEDIUM: 'MINERGIE',
      HIGH: 'MINERGIE',
    },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__SPORT_FREIZEITBAU__MEHRZWECKHALLE: {
    //   LOW: 'NONE',
    //   MEDIUM: 'MINERGIE',
    //   HIGH: 'MINERGIE',
    // },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__HALLENBAD: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },
    FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___GARDEROBENGEBAEUDE: {
      LOW: 'NONE',
      MEDIUM: 'NONE',
      HIGH: 'MINERGIE',
    },
    // FREIZEIT_SPORT_ERHOLUNGSBAUTEN__GEB_SPORTAUSSENANLAGEN___KLUBHAEUSER: {
    //   LOW: 'NONE',
    //   MEDIUM: 'NONE',
    //   HIGH: 'MINERGIE',
    // },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    SCHUTZBAUTEN__FEUERWEHR: { LOW: 'NONE', MEDIUM: 'MINERGIE', HIGH: 'MINERGIE' },

    VERKEHRSBAUTEN__WERKHOEFE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    VERKEHRSBAUTEN__ZOLL: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__BAHN_ETC: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__PARKHAUS: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__PW_GARAGE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__GEWERB_FAHRZEUGHALLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__HOCHGARAGE__UEBERDACHUNG: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PARKHAUS: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__PW_GARAGE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__HOCH_TIEFGARAGE__TIEFGARAGE__GEWERB_FAHRZEUGHALLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__WERKHOEFE__DIENSTRAUM: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__WERKHOEFE__WERKSTATT: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE__TEMPERIERT: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__WERKHOEFE__FAHRZEUGHALLE__NICHT_BEHEIZT: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__WERKHOEFE__LAGER_TEMPERIERT: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__WERKHOEFE__LAGER_NICHT_BEHEIZT: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
    // VERKEHRSBAUTEN__WERKHOEFE__OFFENE_LAGERHALLE: { LOW: 'NONE', MEDIUM: 'NONE', HIGH: 'NONE' },
  };
}
